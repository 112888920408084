<template>
  <div class="test-deck-review-body vtx-test-deck-review-body">
    <TestDeckCalloutRow
      :test-deck="testDeck"
      :test-expectations="distinctTestExpectations"
    />
    <CRow class="mt-4">
      <CCol>
        <CCard>
          <CCardHeader class="d-flex">
            <div class="h4 card-title">
              <CIcon name="cil-globe-alt" class="mr-2" size="lg" />Testing Map
            </div>
            <div style="margin-left: auto; margin-right: 0">
              <CToggler
                in-header
                @click.prevent="
                  () => {
                    if (!isLoading) {
                      if (showMap) {
                        clearMapToolTips();
                      }
                      showMap = !showMap;
                    }
                  }
                "
              >
                <CIcon
                  :name="showMap ? 'cis-chevron-bottom' : 'cis-chevron-left'"
                />
              </CToggler>
            </div>
          </CCardHeader>
          <CCollapse :show.sync="showMap">
            <CCardBody class="map-wrapper">
              <div id="test-deck-map" class="map-container"></div>
            </CCardBody>
          </CCollapse>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCard class="filters-card">
          <CCardHeader>
            <CRow>
              <CCol col="4">
                <h4 class="card-title">
                  <CIcon name="cil-filter" class="mr-2" size="lg" />Filters
                </h4>
              </CCol>
              <CCol col="8">
                <div class="d-flex">
                  <div style="margin-left: auto; margin-right: 0">
                    <template v-if="!showFilters">
                      <CBadge
                        v-for="label in filterLabels"
                        :id="`bdg-filter-label-badge_${label.filterKey}`"
                        :key="label.filterKey"
                        class="h5 ml-2 filter-label-badge"
                        :color="label.color"
                        >{{ label.label
                        }}<small v-if="label.labelSubText">{{
                          label.labelSubText
                        }}</small>
                        <CIcon
                          :id="`btn-filter-label-badge-close_${label.filterKey}`"
                          name="cil-x-circle"
                          class="ml-2 clickable badge-close"
                          title="Remove Filter"
                          role="button"
                          @click.native="removeFilter(label.filterKey)"
                        />
                      </CBadge>
                    </template>
                    <CToggler
                      id="tgl-test-deck-review-filters"
                      class="ml-2"
                      in-header
                      @click.prevent="
                        () => {
                          showFilters = !showFilters;
                        }
                      "
                    >
                      <CIcon
                        :name="
                          showFilters
                            ? 'cis-chevron-bottom'
                            : 'cis-chevron-left'
                        "
                      />
                    </CToggler>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCollapse :show.sync="showFilters">
            <CCardBody>
              <CRow>
                <CCol col="4">
                  <label for="test-deck-filter-ship-to">Ship To</label>
                  <MGeoFilter
                    id="test-deck-filter-ship-to"
                    :options="shipToGeos"
                    :value.sync="selectedShipToGeos"
                  />
                </CCol>
                <CCol col="2" class="text-center">
                  <div>
                    <label for="test-deck-filter-logic">Geo Filter Logic</label>
                  </div>
                  <CButton
                    id="test-deck-filter-logic"
                    color="info"
                    @click="toggleGeoLogic"
                    >{{ geoLogic }}</CButton
                  >
                  <div class="text-note mt-1">
                    {{
                      geoLogic === 'AND'
                        ? 'Only include Ship To from Ship From'
                        : 'Include Ship To or Ship From'
                    }}
                  </div>
                </CCol>
                <CCol col="4">
                  <label for="test-deck-filter-ship-from">Ship From</label>
                  <MGeoFilter
                    id="test-deck-filter-ship-from"
                    :options="shipFromGeos"
                    :value.sync="selectedShipFromGeos"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="4">
                  <label for="test-deck-filter-transaction-type"
                    >Transaction Type</label
                  >
                  <VtxTransactionTypePicker
                    id="test-deck-filter-transaction-type"
                    :value.sync="selectedTransactionTypes"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCollapse>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <MDataTable
          id="tbl-vtx-test-deck-review"
          :fields="fields"
          fixed
          striped
          :loading="isLoading"
          :items="content"
          :table-filter="{ external: true, lazy: true }"
          :column-filter="false"
          :sorter="{ external: true }"
          :sorter-value.sync="columnSorter"
          :table-filter-value.sync="tableFilterValue"
          :pagination="false"
          :per-page="pageSize"
        >
          <template #DOC_DEST="{ item }">
            <ZoneAddress :value="item.DOC_DEST" />
          </template>
          <template #DOC_ADMIN_DEST="{ item }">
            <ZoneAddress :value="item.DOC_ADMIN_DEST" />
          </template>
          <template #DOC_PHYS_ORIGIN="{ item }">
            <ZoneAddress :value="item.DOC_PHYS_ORIGIN" />
          </template>
          <template #DOC_ADMIN_ORIGIN="{ item }">
            <ZoneAddress :value="item.DOC_ADMIN_ORIGIN" />
          </template>
          <template #table-controls>
            <MTablePaginator
              id="pg-test-deck-review-paginator"
              :page.sync="pageNumber"
              :per-page.sync="pageSize"
              :total-rows="totalCount"
              :page-count="totalPages"
              :filtered-rows="content.length"
              :filtering="false"
            >
            </MTablePaginator>
          </template>
          <template #details="{ item }">
            <div class="invoice-line-details-wrapper">
              <CCard
                accent-color="info"
                align="left"
                class="invoice-line-details-card"
              >
                <CCardHeader>
                  <div class="d-flex">
                    <MDetailsButton
                      :id="`btn-show-invoice-details_${item.id}`"
                      @click="doShowInvoiceDetails(item)"
                    />
                    <span
                      class="h4 ml-2"
                      style="margin-top: auto; margin-bottom: auto"
                      >&nbsp;Line Details: {{ item.DOC_NUM }}</span
                    >
                    <span
                      class="text-muted"
                      style="
                        margin-left: 5px;
                        margin-top: auto;
                        margin-bottom: auto;
                      "
                      >({{ item.lines.length }}
                      {{ $plural('entity.line', item.lines.length) }})</span
                    >
                  </div>
                </CCardHeader>
                <CCardBody class="invoice-line-details-container">
                  <VtxDocumentLine
                    v-for="line in item.lines"
                    :key="line.key"
                    :value="line"
                  />
                </CCardBody>
              </CCard>
            </div>
          </template>
        </MDataTable>
      </CCol>
    </CRow>
    <VtxInvoiceDetailsModal
      v-if="showInvoiceDetails"
      id="mod-vtx-invoice-details"
      :show.sync="showInvoiceDetails"
      :value="selectedInvoice"
    />
  </div>
</template>
<script>
import { MGeoFilter } from '@/components/form/MGeoPicker';
import {
  mapCountryDataForSvg,
  createSvgTestDeckMap,
} from '@/utils/parsing/testDeck';
import { mapDocument } from './VtxTestDeckReview.js';
import { VtxInvoiceDetailsModal } from '../_components/VtxInvoiceDetailsModal';
import api from '@/api';
import ZoneAddress from '@/views/testing/_components/ZoneAddress';
import TestDeckCalloutRow from '@/views/testing/decks/TestDeckCalloutRow';
import VtxDocumentLine from './VtxDocumentLine';
import { VTX_DOC_FIELDS, getDocumentFieldName } from '@/utils/vtxFieldUtils';
import VtxTransactionTypePicker from '@/components/TestDeck/vtx/VtxTransactionTypePicker';
import MTablePaginator from '@/components/MDataTable/MTablePaginator';
export default {
  name: 'VtxTestDeckReview',
  components: {
    TestDeckCalloutRow,
    ZoneAddress,
    VtxDocumentLine,
    VtxInvoiceDetailsModal,
    MGeoFilter,
    VtxTransactionTypePicker,
    MTablePaginator,
  },
  props: {
    testDeckId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showInvoiceDetails: false,
      selectedInvoice: null,
      showTestDeckRunModal: false,
      showFilters: false,
      taxEngineType: 'VTX',
      isLoading: false,
      page: 1,
      size: 50,
      content: [],
      totalCount: 0,
      totalPages: 1,
      transactionFields: [],
      internalTableFilterValue: null,
      internalColumnFilterValue: null,
      distinctTestExpectations: 0,
      internalSorter: {
        column: 'DOC_NUM',
        asc: true,
      },
      shipToGeos: [],
      shipFromGeos: [],
      filters: {
        shipFrom: null,
        shipTo: null,
        transactionTypes: null,
        companyRoles: null,
        recordStatus: null,
      },
      testDeck: {
        name: null,
        filename: null,
        createdAt: null,
        itemCount: 0,
        description: null,
      },
      showMap: false,
      mapCountries: {},
      geoLogic: 'AND',
    };
  },
  computed: {
    transactionTypeFilter: {
      get() {
        return this.filters.transactionTypes;
      },
      set(v) {
        this.filters.transactionTypes = v;
      },
    },
    selectedShipFromGeos: {
      get() {
        return this.filters.shipFrom;
      },
      set(v) {
        this.filters.shipFrom = v;
        this.fetchTestDeck(this.testDeckId, {
          ...this.queryArgs,
          page: 0,
          shipFrom: v,
        });
      },
    },
    selectedShipToGeos: {
      get() {
        return this.filters.shipTo;
      },
      set(v) {
        this.filters.shipTo = v;
        this.fetchTestDeck(this.testDeckId, {
          ...this.queryArgs,
          page: 0,
          shipTo: v,
        });
      },
    },
    selectedTransactionTypes: {
      get() {
        return this.filters.transactionTypes;
      },
      set(v) {
        this.filters.transactionTypes = v;
        this.fetchTestDeck(this.testDeckId, {
          ...this.queryArgs,
          page: 0,
          transactionTypes: v,
        });
      },
    },
    pageNumber: {
      get() {
        return this.page;
      },
      set(v) {
        if (this.isLoading) {
          return;
        }

        let pageNumber = v;
        if (!v || v < 1) {
          pageNumber = 1;
        }

        if (pageNumber !== this.page) {
          this.fetchTestDeck(this.testDeckId, {
            ...this.queryArgs,
            page: pageNumber - 1,
          });
        }

        this.page = v;
      },
    },
    pageSize: {
      get() {
        return this.size;
      },
      set(v) {
        let pageSize = v;
        if (!v || v < 5) {
          pageSize = 5;
        }

        if (this.size !== pageSize) {
          this.fetchTestDeck(this.testDeckId, {
            ...this.queryArgs,
            size: pageSize,
            page: 0,
          });
        }
        this.size = v;
      },
    },
    queryArgs() {
      return {
        page: this.pageNumber - 1,
        size: this.pageSize,
        queryText: this.tableFilterValue?.trim(),
        sort: this.calculatedSort,
        shipTo: this.filters.shipTo || [],
        shipFrom: this.filters.shipFrom || [],
        transactionTypes: this.filters.transactionTypes || [],
        geoLogic: this.geoLogic,
      };
    },
    filterLabels() {
      const tags = [];
      if (this.filters.shipTo && this.filters.shipTo.length > 0) {
        tags.push({
          label: 'Ship To',
          labelSubText: `(${this.filters.shipTo.length})`,
          color: 'info',
          filterKey: 'shipTo',
        });
      }
      if (this.filters.shipFrom && this.filters.shipFrom.length > 0) {
        tags.push({
          label: `Ship From`,
          labelSubText: `(${this.filters.shipFrom.length})`,
          color: 'info',
          filterKey: 'shipFrom',
        });
      }

      if (
        this.filters.transactionTypes &&
        this.filters.transactionTypes.length > 0
      ) {
        tags.push({
          label: 'Transaction Type',
          labelSubText: `(${this.filters.transactionTypes.length})`,
          color: 'info',
          filterKey: 'transactionTypes',
        });
      }

      return tags;
    },
    tableFilterValue: {
      get() {
        return this.internalTableFilterValue;
      },
      set(v) {
        this.internalTableFilterValue = v;
        let qa = {
          ...this.queryArgs,
          page: 0,
          queryText: v?.trim(),
        };
        this.fetchTestDeck(this.testDeckId, qa);
      },
    },
    fields() {
      const fieldSet = new Set(this.transactionFields);
      return VTX_DOC_FIELDS.map((field) => {
        return {
          ...field,
          hidden: !fieldSet.has(field.key),
          sorter: field.sortable === true,
        };
      });
    },
    calculatedSort() {
      const column = this.columnSorter.column;
      return `${column} ${this.columnSorter.asc ? 'ASC' : 'DESC'}`;
    },
    columnSorter: {
      get() {
        return this.internalSorter;
      },
      set(v) {
        let sort;
        if (!v) {
          sort = {
            external: true,
            resetable: false,
            column: 'DOC_NUM',
            asc: true,
          };
        } else {
          let column = v.column || 'DOC_NUM';
          let asc = v.asc === false ? false : true;

          sort = {
            external: true,
            resetable: false,
            column,
            asc,
          };
        }

        this.internalSorter = sort;

        const qa = {
          ...this.queryArgs,
          page: 0,
          sort: `${sort.column} ${sort.asc ? 'ASC' : 'DESC'}`,
        };

        this.fetchTestDeck(this.testDeckId, qa);
      },
    },
  },
  watch: {
    testRunId(v) {
      const self = this;
      this.fetchTestDeck(v, this.queryArgs).then((data) => {
        const countries = mapCountryDataForSvg(
          data.metadata?.metrics?.shipTo || {},
          data.metadata?.metrics?.shipFrom || {},
        );
        self.mapCountries = Object.freeze(countries);
      });
    },
    showMap(v) {
      if (v && !this.$svgMap) {
        this.$nextTick(() => {
          this.$svgMap = createSvgTestDeckMap(
            'test-deck-map',
            this.mapCountries,
          );
          this.generateClickablePaths();
        });
      }
    },
  },
  mounted() {
    const self = this;
    this.fetchTestDeck(this.testDeckId).then((data) => {
      const countries = mapCountryDataForSvg(
        data.metadata?.metrics?.shipTo || {},
        data.metadata?.metrics?.shipFrom || {},
      );
      self.mapCountries = Object.freeze(countries);
      if (self.showMap && !self.$svgMap) {
        self.$svgMap = createSvgTestDeckMap('test-deck-map', countries);
        self.generateClickablePaths();
      }
    });
  },
  methods: {
    clearMapToolTips() {
      this.$nextTick(() => {
        Array.from(document.getElementsByClassName('svgMap-tooltip')).forEach(
          (elm) => {
            elm.classList.remove('svgMap-active');
          },
        );
      });
    },
    doShowInvoiceDetails(item) {
      this.selectedInvoice = item;
      this.showInvoiceDetails = true;
    },
    toggleGeoLogic() {
      const logic = this.geoLogic === 'AND' ? 'OR' : 'AND';
      this.geoLogic = logic;
      if (
        this.selectedShipToGeos?.length > 0 ||
        this.selectedShipFromGeos?.length > 0
      ) {
        this.fetchTestDeck(this.testDeckId, {
          ...this.queryArgs,
          geoLogic: logic,
          page: 0,
        });
      }
    },
    removeFilter(filterKey) {
      switch (filterKey) {
        case 'shipTo':
          this.selectedShipToGeos = [];
          break;
        case 'shipFrom':
          this.selectedShipFromGeos = [];
          break;
        case 'transactionTypes':
          this.selectedTransactionTypes = [];
          break;
      }
    },
    fetchTestDeck(id, queryArgs) {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      const self = this;
      return new Promise((resolve, reject) => {
        api.tests.decks
          .getTestDeckDetails(id, queryArgs)
          .then((res) => {
            self.testDeck = {
              name: res.testDeck.name,
              filename: res.testDeck.filename,
              itemCount: res.testDeck.itemCount,
              description: res.testDeck.description?.trim(),
              createdAt: self.$format.timestamp(res.testDeck.createdAt),
            };

            self.taxEngineType = res.testDeck.taxEngineType;

            const content = res.content.map((o) => {
              return Object.freeze(mapDocument(o));
            });

            const shipToGeos = res.filterOptions?.shipTo || [];
            const shipFromGeos = res.filterOptions?.shipFrom || [];

            self.content = content;
            self.shipToGeos = shipToGeos;
            self.shipFromGeos = shipFromGeos;
            self.transactionFields = [
              ...new Set(
                (res.testDeck.transactionFields || [])
                  .map((f) => {
                    return getDocumentFieldName(f);
                  })
                  .filter((f) => {
                    return !!f;
                  }),
              ),
            ];

            self.totalPages = res.totalPages;
            self.totalCount = res.totalElements;
            self.page = (res.pageable?.pageNumber || 0) + 1;

            self.distinctTestExpectations = (
              res.testDeck.transactionFields || []
            ).filter((f) => {
              return f.toUpperCase().startsWith('EXP');
            }).length;

            resolve({
              testDeck: res.testDeck,
              content,
              metadata: res.testDeck?.metadata,
            });
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            self.$nextTick(() => {
              self.isLoading = false;
            });
          });
      });
    },
    handleCountryClick(isoCode) {
      let shipTo = [];
      let shipFrom = [];

      const shipToGeos = this.shipToGeos.filter(
        (g) =>
          g.key === isoCode ||
          g.countryKey === isoCode ||
          g.parentKey === isoCode,
      );

      if (shipToGeos.length > 1) {
        shipTo = shipToGeos.filter((g) => g.key !== isoCode).map((g) => g.key);
      } else {
        shipTo = shipToGeos.map((g) => g.key);
      }

      const shipFromGeos = this.shipFromGeos.filter(
        (g) =>
          g.key === isoCode ||
          g.countryKey === isoCode ||
          g.parentKey === isoCode,
      );

      if (shipFromGeos.length > 1) {
        shipFrom = shipFromGeos
          .filter((g) => g.key !== isoCode)
          .map((g) => g.key);
      } else {
        shipFrom = shipFromGeos.map((g) => g.key);
      }

      this.filters.shipTo = shipTo;
      this.filters.shipFrom = shipFrom;
      this.geoLogic = 'OR';

      this.fetchTestDeck(this.testDeckId, {
        ...this.queryArgs,
        geoLogic: 'OR',
        shipTo,
        shipFrom,
      });
    },
    generateClickablePaths() {
      const self = this;
      this.$nextTick(() => {
        const svgMap = document.getElementById('test-deck-map');
        const countryPaths = svgMap.getElementsByClassName('svgMap-country');

        for (let country of countryPaths) {
          const isoCode = country.getAttribute('data-id');
          if (self.mapCountries[isoCode]) {
            country.addEventListener('click', () => {
              self.handleCountryClick(isoCode);
            });
            country.classList.add('clickable');
          }
        }
      });
    },
  },
};
</script>
