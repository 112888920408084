<template>
  <CCard>
    <CCardHeader>
      <CRow>
        <template v-if="line.products.length > 0">
          <CCol
            v-for="(product, index) in line.products"
            :key="`${product.type}_${index}`"
          >
            <CCallout :color="product.code ? 'info' : 'secondary'">
              <small class="text-muted">{{
                $t(`productType.${product.type}`)
              }}</small
              ><br />
              <strong class="h4">{{ product.code || '--' }}</strong>
            </CCallout>
          </CCol>
        </template>
        <CCol v-else>
          <CCallout color="secondary">
            <small class="text-muted">Product Code</small><br />
            <strong class="h4 text-muted">--</strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout :color="line.quantity ? 'info' : 'secondary'">
            <small class="text-muted">Quantity</small><br />
            <strong class="h4">{{ line.quantity || '--' }}</strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout :color="line.grossAmount ? 'info' : 'secondary'">
            <small class="text-muted">Gross</small><br />
            <strong class="h4">
              {{ line.grossAmount || '--' }}
            </strong>
          </CCallout>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow
        v-if="line.hasRegistrations || line.hasCounterPartyInfo"
        class="pb-4"
      >
        <CCol v-if="line.hasRegistrations">
          <SbxRegistrationsBlock v-bind="line.registrations" />
        </CCol>
        <CCol v-if="line.hasCounterPartyInfo">
          <SbxCounterPartyBlock v-bind="line.counterParty" />
        </CCol>
      </CRow>
      <CRow
        v-if="line.hasLineExpectations || line.hasCustomAttributes"
        class="pb-4"
      >
        <CCol>
          <fieldset>
            <legend>Line Level Test Expectations</legend>
            <div v-if="line.hasLineExpectations" class="user-element-container">
              <LabeledValue
                v-for="(exp, expIndex) in line.expectations"
                :key="`${expIndex}_${exp.name}`"
                :label="exp.label"
                :value="exp.value"
              />
            </div>
            <div v-else class="text-muted h4 mt-2 ml-1">None</div>
          </fieldset>
        </CCol>
        <CCol>
          <fieldset>
            <legend>User Elements</legend>
            <div v-if="line.hasCustomAttributes" class="user-element-container">
              <LabeledValue
                v-for="e in line.customAttributes"
                :key="e.key"
                :label="e.label"
                :value="e.value"
              />
            </div>
            <div v-else class="text-muted h4 mt-2 ml-1">None</div>
          </fieldset>
        </CCol>
      </CRow>
      <CRow v-if="line.addresses.length > 0" class="pb-4">
        <CCol>
          <fieldset>
            <legend>Addresses</legend>
            <CRow class="pt-2">
              <CCol
                v-for="(address, addressIndex) in line.addresses"
                :key="`${line.lineNumber}_${addressIndex}_${address.name}`"
                col="6"
                md="4"
              >
                <DocumentAddress :value="address" :label="address.label" />
              </CCol>
            </CRow>
          </fieldset>
        </CCol>
      </CRow>
      <CRow v-if="(line.lineAttributes || []).length > 0" class="pb-4">
        <CCol>
          <fieldset>
            <legend>Line Attributes</legend>
            <div class="user-element-container">
              <LabeledValue
                v-for="e in line.lineAttributes"
                :key="e.key"
                :label="e.label"
              >
                <MCheckIcon
                  v-if="e.fieldType === 'BOOLEAN'"
                  :value="e.value"
                  show-false
                />
                <span v-else>
                  {{ e.value }}
                </span>
              </LabeledValue>
            </div>
          </fieldset>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<script>
import DocumentAddress from '@/components/DocumentAddress';
import SbxRegistrationsBlock from './SbxRegistrationsBlock';

export default {
  name: 'SbxInvoiceLineTab',
  components: { DocumentAddress, SbxRegistrationsBlock },
  props: {
    line: {
      type: Object,
      required: true,
    },
  },
};
</script>
