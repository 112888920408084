<template>
  <CRow :id="lineKey" class="line-detail-row vtx-line-detail-row">
    <CCol col="1" class="line-number-col">
      <div class="text-uppercase text-muted small">Line</div>
      <div class="text-value-lg">{{ lineNumber }}</div>
    </CCol>
    <CCol
      col="3"
      class="expectations-col"
      :class="{ 'empty-col': !hasExpectations }"
    >
      <div
        class="col-label c-callout"
        :class="{
          'text-muted': !hasExpectations,
          'c-callout-primary': hasExpectations,
        }"
      >
        Expectations
      </div>
      <dl v-if="hasExpectations" class="row expectations-list">
        <template v-for="expectation in expectations">
          <dt :key="`${lineKey}_${expectation.name}_dt`" class="col-5">
            {{ expectation.label.replaceAll('Exp. ', '') }}:
          </dt>
          <dd :key="`${lineKey}_${expectation.name}_dd`" class="col-7">
            <pre>{{ expectation.value }}</pre>
          </dd>
        </template>
      </dl>
      <div v-else class="empty-col-section text-muted">None</div>
    </CCol>
    <CCol col="3" class="product-col">
      <div class="col-label c-callout c-callout-primary">Product</div>
      <dl class="row product-list">
        <template v-if="hasProduct">
          <template v-for="(product, index) in products">
            <dt :key="`${lineKey}_${product.type}_${index}_dt`" class="col-5">
              {{ $t(`productType.${product.type}`) }}:
            </dt>
            <dd :key="`${lineKey}_${product.type}_${index}_dd`" class="col-7">
              <pre>{{ product.code }}</pre>
            </dd>
          </template>
        </template>
        <dt class="col-5">Quantity:</dt>
        <dd class="col-7">
          <pre>{{ quantity || '--' }}</pre>
        </dd>
        <dt class="col-5">Extended Price:</dt>
        <dd class="col-7">
          <pre>{{ grossAmount || '--' }}</pre>
        </dd>
      </dl>
    </CCol>
    <CCol
      col="2"
      class="address-col"
      :class="{ 'empty-col': !hasAddresses, 'text-muted': !hasAddresses }"
    >
      <div
        class="col-label c-callout"
        :class="{
          'text-muted': !hasAddresses,
          'c-callout-primary': hasAddresses,
        }"
      >
        Addresses
      </div>
      <dl v-if="hasAddresses" class="row zone-address-list">
        <template v-for="address in addresses">
          <dt :key="`${lineKey}_${address.name}_dt`" class="col-5">
            {{ address.label }}:
          </dt>
          <dd :key="`${lineKey}_${address.name}_dd`" class="col-7">
            <ZoneAddress :value="address" />
          </dd>
        </template>
      </dl>
      <div v-else class="empty-col-section text-muted">None</div>
    </CCol>

    <CCol
      col="2"
      class="address-col"
      :class="{ 'empty-col': !hasFlexFields, 'text-muted': !hasFlexFields }"
    >
      <div
        class="col-label c-callout"
        :class="{
          'text-muted': !hasFlexFields,
          'c-callout-primary': hasFlexFields,
        }"
      >
        Flex Fields
      </div>
      <dl v-if="hasFlexFields" class="row zone-address-list">
        <template v-for="flexField in flexFields">
          <dt :key="`${lineKey}_${flexField.key}_dt`" class="col-5">
            {{ flexField.label }}:
          </dt>
          <dd :key="`${lineKey}_${flexField.key}_dd`" class="col-7">
            <pre>{{ flexField.value }}</pre>
          </dd>
        </template>
      </dl>
      <div v-else class="empty-col-section text-muted">None</div>
    </CCol>
  </CRow>
</template>
<script>
import ZoneAddress from '@/views/testing/_components/ZoneAddress';
import { getFieldForProperty } from '@/utils/vtxFieldUtils';
export default {
  name: 'VtxDocumentLine',
  components: {
    ZoneAddress,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return this.mapLine(this.value);
  },
  methods: {
    mapLineExpectations(expectations) {
      return expectations.map((e) => {
        let retval = {
          ...e,
          label: getFieldForProperty(e.name)?.label || e.name,
        };
        // Hack for improperly serialized JSON for right now.
        if (retval.value.startsWith('{') && retval.value.endsWith('}')) {
          retval.value = retval.value.split(',')[0].split('=')[1];
        }
        return retval;
      });
    },
    mapLineAddresses(addresses) {
      return addresses
        .map((a) => {
          let addressProp = '';
          switch (a.addressType) {
            case 'DESTINATION':
              addressProp = 'LINE_DEST';
              break;
            case 'ADMININISTRATIVE_DESTINATION':
              addressProp = 'LINE_ADMIN_DEST';
              break;
            case 'PHYSICAL_ORIGIN':
              addressProp = 'LINE_PHYS_ORIGIN';
              break;
            case 'ADMINISTRATIVE_ORIGIN':
              addressProp = 'LINE_ADMIN_ORIGIN';
              break;
          }
          if (addressProp === '' || a.empty === true) {
            return null;
          }

          return {
            addressType: addressProp,
            label: this.$t(`addressType.${addressProp}`),
            country: a.country,
            division: a.mainDivision,
            county: a.subDivision,
            address1: a.streetAddress1,
            address2: a.streetAddress2,
            taxAreaId: a.taxAreaId,
            locationCode: a.locationCode,
            zoneType: 'VTX',
          };
        })
        .filter((a) => {
          return !!a;
        });
    },
    mapLine(line) {
      const data = {
        lineKey: line.key,
        lineNumber: line.lineNumber,
        quantity: this.$format.number(line.quantity),
        grossAmount: line.grossAmount,
        products: line.products || [],
        addresses: this.mapLineAddresses(line.addresses || []),
        expectations: this.mapLineExpectations(line.expectations || []),
        hasProduct: false,
        hasAddresses: false,
        hasExpectations: false,
        hasFlexFields: false,
        flexFields: [],
      };

      data.hasAddresses = data.addresses.length > 0;
      data.hasProduct = data.products.length > 0;
      data.hasExpectations = data.expectations.length > 0;

      if (line.hasFlexFields) {
        const mapped = [];
        line.flexibleFields.codeFields.map((f, idx) => {
          mapped.push({
            ...f,
            label: `Code ${f.fieldId}`,
            key: `code_${idx}_${f.fieldId}`,
          });
        });
        line.flexibleFields.dateFields.map((f, idx) => {
          mapped.push({
            ...f,
            label: `Date ${f.fieldId}`,
            key: `date_${idx}_${f.fieldId}`,
            value: f.value.split('T')[0],
          });
        });
        line.flexibleFields.numberFields.map((f, idx) => {
          mapped.push({
            ...f,
            label: `Number ${f.fieldId}`,
            key: `num_${idx}_${f.fieldId}`,
          });
        });
        data.flexFields = mapped;
        data.hasFlexFields = mapped.length > 0;
      }

      return data;
    },
  },
};
</script>
