<template>
  <CCard>
    <CCardHeader>
      <CRow>
        <CCol>
          <CCallout :color="line.itemCode ? 'info' : 'secondary'">
            <small class="text-muted">Item Code</small><br />
            <strong class="h4">
              {{ line.itemCode || '--' }}
            </strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout :color="line.quantity ? 'info' : 'secondary'">
            <small class="text-muted">Quantity</small><br />
            <strong class="h4">{{ line.quantity || '--' }}</strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout :color="line.amount ? 'info' : 'secondary'">
            <small class="text-muted">Amount</small><br />
            <strong class="h4">
              {{ grossAmount || '--' }}
            </strong>
          </CCallout>
        </CCol>
      </CRow>
      <CRow v-if="line.description">
        <CCol>
          <fieldset>
            <legend>Line Description</legend>
            <div v-if="line.description" class="user-element-container">
              <LabeledValue :value="line.description" />
            </div>
            <div v-else class="text-muted h4 mt-2 ml-1">--</div>
          </fieldset>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow v-if="hasLineExpectations || hasUserElements" class="pb-4">
        <CCol>
          <fieldset>
            <legend>Line Level Test Expectations</legend>
            <div v-if="hasLineExpectations" class="user-element-container">
              <LabeledValue
                v-for="(exp, expIndex) in expectations"
                :key="`${expIndex}_${exp.name}`"
                :label="exp.label"
                :value="exp.value"
              />
            </div>
            <div v-else class="text-muted h4 mt-2 ml-1">None</div>
          </fieldset>
        </CCol>
        <CCol>
          <fieldset>
            <legend>User Defined Fields</legend>
            <div v-if="hasUserElements" class="user-element-container">
              <LabeledValue
                v-for="e in userElements"
                :key="e.key"
                :label="e.label"
                :value="e.value"
              />
            </div>
            <div v-else class="text-muted h4 mt-2 ml-1">None</div>
          </fieldset>
        </CCol>
      </CRow>
      <CRow v-if="addresses.length > 0" class="pb-4">
        <CCol>
          <fieldset>
            <legend>Addresses</legend>
            <CRow class="pt-2">
              <CCol
                v-for="(address, addressIndex) in addresses"
                :key="`${line.lineNumber}_${addressIndex}_${address.name}`"
                col="6"
                md="4"
              >
                <DocumentAddress :value="address" :label="address.label" />
              </CCol>
            </CRow>
          </fieldset>
        </CCol>
      </CRow>
      <CRow v-if="(line.lineProperties || []).length > 0" class="pb-4">
        <CCol>
          <fieldset>
            <legend>Line Properties</legend>
            <div class="user-element-container">
              <LabeledValue
                v-for="e in line.lineProperties"
                :key="e.key"
                :label="e.label"
              >
                <MCheckIcon
                  v-if="e.fieldType === 'BOOLEAN'"
                  :value="e.value"
                  show-false
                />
                <span v-else>
                  {{ e.value }}
                </span>
              </LabeledValue>
            </div>
          </fieldset>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<script>
import DocumentAddress from '@/components/DocumentAddress';
import { titleCase } from '@/utils/helpers';
import { getFieldForProperty, isIgnoredField } from '@/utils/ava/avaFieldUtils';
export default {
  name: 'AvaDocumentLineTab',
  components: { DocumentAddress },
  props: {
    line: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expectations: [],
      userElements: [],
      lineAttributes: [],
      grossAmount: null,
      addresses: [],
    };
  },
  computed: {
    hasLineExpectations() {
      return this.expectations.length > 0;
    },
    hasUserElements() {
      return this.userElements.size > 0;
    },
    hasLineAttributes() {
      return this.lineAttributes.length > 0;
    },
  },
  mounted() {
    this.grossAmount = this.$format.currency(
      this.line.currencyCode,
      this.line.amount,
    );

    this.expectations = Object.keys(this.line.expectations || {})
      .map((key) => {
        const exp = this.line.expectations[key];
        const field = getFieldForProperty(key);
        return Object.freeze({
          label: titleCase(
            field?.label?.replace('EXP. LINE ', '') ||
              exp.name.replace('EXPECTED.LINE.').replace('_', ' '),
            true,
          ),
          value: exp.properties.expectation,
        });
      })
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label);
      });

    this.userElements = Object.keys(this.line.userDefinedFields || {})
      .map((field) => {
        return Object.freeze({
          label: field,
          value: this.line.userDefinedFields[field],
        });
      })
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label);
      });

    this.addresses = Object.keys(this.line.addresses || {})
      .map((group) => {
        return Object.freeze({
          ...this.line.addresses[group],
          label: this.$t(`addressType.${group.replace('LINE.', '')}`),
        });
      })
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label);
      });

    this.lineAttributes = Object.keys(this.line.lineProperties)
      .reduce((acc, key) => {
        const field = getFieldForProperty(key);
        if (!!field && !isIgnoredField(key)) {
          acc.push(
            Object.freeze({
              label: titleCase(field.label, true),
              value: this.line.lineProperties[key],
            }),
          );
        }

        return acc;
      }, [])
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label);
      });
  },
};
</script>
