<template>
  <CRow :id="lineKey" class="line-detail-row sbx-line-detail-row">
    <CCol col="1" class="line-number-col">
      <div class="text-uppercase text-muted small">Line</div>
      <div class="text-value-lg">{{ lineNumber }}</div>
    </CCol>

    <CCol
      col="3"
      class="expectations-col"
      :class="{ 'empty-col': !hasExpectations }"
    >
      <div
        class="col-label c-callout"
        :class="{
          'text-muted': !hasExpectations,
          'c-callout-primary': hasExpectations,
        }"
      >
        Expectations
      </div>
      <dl v-if="hasExpectations" class="row expectations-list">
        <template v-for="expectation in expectations">
          <dt :key="`exp_${lineKey}_${expectation.name}_dt`" class="col-5">
            {{ expectation.label }}:
          </dt>
          <dd :key="`exp_${lineKey}_${expectation.name}_dd`" class="col-7">
            <pre>{{ expectation.value }}</pre>
          </dd>
        </template>
      </dl>
      <div v-else class="empty-col-section text-muted">None</div>
    </CCol>
    <CCol col="3" class="product-col">
      <div class="col-label c-callout c-callout-primary">Product</div>
      <dl class="row product-list">
        <dt class="col-5">Item Code</dt>
        <dd class="col-7">
          <pre>{{ itemCode || '--' }}</pre>
        </dd>
        <dt class="col-5">Quantity:</dt>
        <dd class="col-7">
          <pre>{{ quantity || '--' }}</pre>
        </dd>
        <dt class="col-5">Amount:</dt>
        <dd class="col-7">
          <pre>{{ grossAmount || '--' }}</pre>
        </dd>
      </dl>
    </CCol>
    <CCol
      col="3"
      class="custom-attributes-col"
      :class="{ 'empty-col': !hasUserDefinedFields }"
    >
      <div
        class="col-label c-callout"
        :class="{
          'text-muted': !hasUserDefinedFields,
          'c-callout-primary': hasUserDefinedFields,
        }"
      >
        User Defined Fields
      </div>
      <dl v-if="hasUserDefinedFields" class="row custom-attributes-list">
        <template v-for="attribute in userDefinedFields">
          <dt :key="`attr_${lineKey}_${attribute.name}_dt`" class="col-5">
            {{ attribute.label }}:
          </dt>
          <dd :key="`attr_${lineKey}_${attribute.name}_dd`" class="col-7">
            <pre>{{ attribute.value }}</pre>
          </dd>
        </template>
      </dl>
      <div v-else class="empty-col-section text-muted">None</div>
    </CCol>
    <CCol
      col="2"
      class="address-col"
      :class="{ 'empty-col': !hasAddresses, 'text-muted': !hasAddresses }"
    >
      <div
        class="col-label c-callout"
        :class="{
          'text-muted': !hasAddresses,
          'c-callout-primary': hasAddresses,
        }"
      >
        Addresses
      </div>
      <dl v-if="hasAddresses" class="row zone-address-list">
        <template v-for="address in addresses">
          <dt :key="`addr_${lineKey}_${address.name}_dt`" class="col-5">
            {{ address.label }}:
          </dt>
          <dd :key="`addr_${lineKey}_${address.name}_dd`" class="col-7">
            <ZoneAddress :value="address" />
          </dd>
        </template>
      </dl>
      <div v-else class="empty-col-section text-muted">None</div>
    </CCol>
  </CRow>
</template>
<script>
import ZoneAddress from '@/views/testing/_components/ZoneAddress';
import { titleCase } from '@/utils/helpers';

import { getFieldForProperty } from '@/utils/ava/avaFieldUtils';
export default {
  name: 'AvaDocumentLine',
  components: { ZoneAddress },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lineKey: null,
      lineNumber: 1,
      quantity: 0,
      grossAmount: 0,
      itemCode: null,
      addresses: [],
      expectations: [],
      userDefinedFields: [],
    };
  },
  computed: {
    hasAddresses() {
      return this.addresses.length > 0;
    },
    hasExpectations() {
      return this.expectations.length > 0;
    },
    hasUserDefinedFields() {
      return this.userDefinedFields.length > 0;
    },
  },
  mounted() {
    const v = this.value;
    this.lineKey = v.key;
    this.lineNumber = v.lineNumber;
    this.quantity = this.$format.number(v.quantity);
    this.grossAmount = this.$format.currency(v.currencyCode, v.amount);
    this.itemCode = v.itemCode;
    this.addresses = Object.keys(v.addresses || {})
      .map((key) => {
        const group = key.replace('LINE.', '');
        return Object.freeze({
          ...v.addresses[key],
          group,
          label: this.$t(`addressType.${group}`),
        });
      })
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label);
      });

    this.userDefinedFields = Object.keys(v.userDefinedFields || {})
      .map((field) => {
        return Object.freeze({
          name: field,
          label: field,
          value: v.userDefinedFields[field],
        });
      })
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label);
      });

    this.expectations = Object.keys(v.expectations || {})
      .map((key) => {
        const exp = v.expectations[key];
        const field = getFieldForProperty(key);
        return Object.freeze({
          label: titleCase(
            field?.label?.replace('EXP. LINE ', '') ||
              exp.name.replace('EXPECTED.LINE.'),
            true,
          ),
          name: field?.escaped || exp.name || key,
          value: exp.properties.expectation,
        });
      })
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label);
      });
  },
};
</script>
