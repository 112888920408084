<template>
  <CModal
    class="ava-invoice-details-modal modal-xxl"
    :show.sync="modalShow"
    size="xl"
    centered
    :close-on-backdrop="false"
  >
    <template #header>
      <h2>Document: {{ documentCode }}</h2>
    </template>
    <CCard class="document-details-card ava-document-details-card">
      <CCardBody>
        <CRow>
          <CCol>
            <CCallout color="primary">
              <small class="text-muted">Company Code</small><br />
              <strong class="h4">{{ companyCode }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout color="primary">
              <small class="text-muted">Customer Code</small><br />
              <strong class="h4">{{ customerCode }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout color="primary">
              <small class="text-muted">Document Type</small><br />
              <strong class="h4">{{ documentType }}</strong>
            </CCallout>
          </CCol>
        </CRow>
        <CRow class="pt-2">
          <CCol col="4">
            <fieldset class="d-inline-flex">
              <legend>General</legend>
              <LabeledValue label="Document Date">
                {{ documentDate || '--' }}
              </LabeledValue>
              <LabeledValue label="Currency Code">
                {{ currencyCode }}
              </LabeledValue>
              <LabeledValue label="Business Id No.">
                {{ businessIdNo || '--' }}
              </LabeledValue>
            </fieldset>
          </CCol>
        </CRow>
        <CTabs :active-tab.sync="activeTab" class="pt-5">
          <CTab title="Document" class="invoice-details-tab">
            <CCard>
              <CCardBody>
                <CRow
                  v-if="hasDocumentExpectations || hasDocumentUserDefinedFields"
                  class="pb-4"
                >
                  <CCol col="6">
                    <fieldset class="d-inline-flex">
                      <legend>Document Level Test Expectations</legend>
                      <div
                        v-if="hasDocumentExpectations"
                        class="user-element-container"
                      >
                        <LabeledValue
                          label="Taxability"
                          :empty="!expectedInvoiceTaxability"
                        >
                          {{ expectedInvoiceTaxability || '--' }}
                        </LabeledValue>
                      </div>
                      <div v-else class="text-muted h4 mt-2 ml-1">
                        None <small class="ml-1">(see lines)</small>
                      </div>
                    </fieldset>
                  </CCol>
                  <CCol v-if="hasDocumentUserDefinedFields" col="6">
                    <fieldset>
                      <legend>User Elements</legend>
                      <div class="user-element-container">
                        <LabeledValue
                          v-for="e in documentUserElements"
                          :key="e.key"
                          :label="e.label"
                          :value="e.value"
                        />
                      </div>
                    </fieldset>
                  </CCol>
                </CRow>
                <CRow v-if="hasDocumentAddresses" class="pb-4">
                  <CCol>
                    <fieldset>
                      <legend>
                        Addresses
                        <small class="text-muted">
                          ({{ documentAddresses.length }})
                        </small>
                      </legend>
                      <CRow>
                        <CCol
                          v-for="docAddress in documentAddresses"
                          :key="docAddress.key"
                          col="6"
                          md="4"
                          lg="3"
                        >
                          <DocumentAddress
                            :value="docAddress"
                            :label="docAddress.label"
                            class="pt-2"
                          />
                        </CCol>
                      </CRow>
                    </fieldset>
                  </CCol>
                </CRow>
                <CRow v-if="documentAttributes.length > 0" class="pb-4">
                  <CCol>
                    <fieldset>
                      <legend>All Other Invoice Attributes</legend>
                      <div
                        v-if="documentAttributes.length > 0"
                        class="user-element-container"
                      >
                        <LabeledValue
                          v-for="e in documentAttributes"
                          :key="e.key"
                          :label="e.label"
                        >
                          <MCheckIcon
                            v-if="e.fieldType === 'BOOLEAN'"
                            :value="e.value"
                            show-false
                          />
                          <span v-else>
                            {{ e.value }}
                          </span>
                        </LabeledValue>
                      </div>
                      <div v-else class="text-muted h4 mt-2 ml-1">None</div>
                    </fieldset>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CTab>
          <CTab :title="`Lines (${lineCount})`" class="line-details-tab">
            <CTabs
              :active-tab="activeLineTab"
              class="pt-4"
              variant="pills"
              :vertical="{
                navs: 'col-xs-2 col-md-1',
                content: 'col-xs-10 col-md-11',
              }"
            >
              <CTab
                v-for="(line, index) in documentLines"
                :key="`${index}_${line.lineNumber}`"
                :title="`Line ${line.lineNumber}`"
              >
                <AvaDocumentLineTab :line="line" />
              </CTab>
            </CTabs>
          </CTab>
        </CTabs>
      </CCardBody>
    </CCard>
    <template #footer>
      <CButton
        id="btn-ava-invoice-details-close"
        color="primary"
        @click="modalShow = false"
        >Close</CButton
      >
    </template>
  </CModal>
</template>
<script>
import DocumentAddress from '@/components/DocumentAddress';
import AvaDocumentLineTab from '@/views/testing/decks/ava/_components/AvaDocumentLineTab';
import {
  isIgnoredField,
  getFieldForProperty,
} from '@/utils/ava/avaFieldUtils.js';
import { titleCase } from '@/utils/helpers';
export default {
  name: 'AvaInvoiceDetailsModal',
  components: {
    AvaDocumentLineTab,
    DocumentAddress,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      activeLineTab: 0,
      businessIdNo: null,
      companyCode: null,
      customerCode: null,
      documentType: null,
      documentDate: null,
      currencyCode: null,
      documentCode: null,
      documentUserElements: [],
      hasOtherElements: false,
      documentAddresses: [],
      documentAttributes: [],
      documentLines: [],
      attributeFields: [
        {
          key: 'label',
          label: 'Name',
        },
        {
          key: 'fieldPath',
          label: 'Path',
          hidden: true,
        },
        {
          key: 'value',
          label: 'Value',
          filter: false,
          sorter: false,
        },
      ],
    };
  },
  computed: {
    modalShow: {
      get() {
        return this.show === true;
      },
      set(v) {
        this.$emit('update:show', v);
      },
    },
    hasDocumentAddresses() {
      return this.documentAddresses.length > 0;
    },
    expectedInvoiceTaxability() {
      return this.value.documentExpectations['EXPECTED.DOCUMENT.TAXABILITY']
        ?.properties?.expectation;
    },
    lineCount() {
      return (this.value.lines || []).length;
    },

    hasDocumentUserDefinedFields() {
      return this.documentUserElements && this.documentUserElements.length > 0;
    },
    hasDocumentExpectations() {
      return !!this.expectedInvoiceTaxability;
    },
  },
  mounted() {
    const v = this.value;
    this.businessIdNo =
      v.documentProperties['DOCUMENT.BUSINESS_IDENTIFICATION_NO'];
    this.companyCode = v.companyCode;
    this.customerCode = v.customerCode;
    this.documentDate = v.documentDate;
    this.currencyCode = v.currencyCode;
    this.documentCode = v.documentCode;
    this.documentType = this.$t(`avaDocType.${v.documentType}`);
    this.documentLines = v.lines.map((line) => {
      return {
        ...line,
        currencyCode: v.currencyCode,
      };
    });

    this.documentAttributes = Object.keys(v.documentProperties)
      .filter((key) => !isIgnoredField(key))
      .map((key) => {
        const field = getFieldForProperty(key);
        const value = v.documentProperties[key];
        return Object.freeze({
          label: titleCase(field?.label || key.replace('DOCUMENT.'), true),
          value: value,
        });
      });

    this.documentAddresses = Object.keys(this.value.addresses || {}).map(
      (key) => {
        const group = key.replace('DOCUMENT.', '');
        return Object.freeze({
          ...this.value.addresses[key],
          group,
          label: this.$t(`addressType.${group}`),
        });
      },
    );

    this.documentUserElements = Object.keys(
      this.value.userDefinedFields || {},
    ).map((field) => {
      return Object.freeze({
        label: field,
        value: this.value.userDefinedFields[field],
      });
    });

    this.expectations = Object.keys(this.value.expectations || {}).map(
      (key) => {
        const exp = this.value.expectations[key];
        const field = getFieldForProperty(key);
        return Object.freeze({
          label: titleCase(
            field?.label?.replace('EXP DOC ', '') ||
              exp.name.replace('EXPECTED.DOCUMENT.'),
            true,
          ),
          value: exp.properties.expectation,
        });
      },
    );
  },
};
</script>
<style lang="scss">
.document-details-card {
  padding-bottom: 0;
  margin-bottom: 0;
}

.tab-pane {
  .card {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@media (max-height: 900px) {
  .code.hljs {
    width: 100%;
    height: 250px;
    overflow-y: auto;
  }

  .tab-pane {
    height: 400px;
    overflow-y: auto;

    .tab-pane {
      height: unset;
      overflow: unset;
    }
  }
}

@media (min-height: 901px) {
  .code.hljs {
    width: 100%;
    height: 350px;
    overflow-y: auto;
  }

  .tab-pane {
    height: 500px;
    overflow-y: auto;

    .tab-pane {
      height: unset;
    }
  }
}

.user-element-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
}

.document-details-card {
  .c-callout {
    margin-top: 0;
  }
  .nav-pills .nav-link {
    color: #39f;
  }
  .nav-pills .nav-link.active {
    color: #fff;
    background-color: #39f;
  }

  fieldset {
    legend {
      font-size: 1rem;
      font-weight: 600;
      padding: 0;
      margin: 0;
    }
    & > div {
      margin-left: -0.25rem;
    }

    & > div.row {
      margin-left: -15px;
    }

    & > div.row > div > .document-address-container {
      margin-left: 0;
    }
  }
  .m-table-wrapper {
    height: 200px !important;
  }
}
</style>
