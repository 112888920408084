<template>
  <CCard>
    <CCardHeader>
      <CRow>
        <template v-if="line.products.length > 0">
          <CCol
            v-for="(product, index) in line.products"
            :key="`${product.type}_${index}`"
          >
            <CCallout :color="product.code ? 'info' : 'secondary'">
              <small class="text-muted">{{
                $t(`productType.${product.type}`)
              }}</small
              ><br />
              <strong class="h4">{{ product.code || '--' }}</strong>
            </CCallout>
          </CCol>
        </template>
        <CCol v-else>
          <CCallout color="secondary">
            <small class="text-muted">Product</small><br />
            <strong class="h4 text-muted">--</strong>
          </CCallout>
        </CCol>

        <CCol>
          <CCallout :color="line.quantity ? 'info' : 'secondary'">
            <small class="text-muted">Quantity</small><br />
            <strong class="h4">{{ line.quantity || '--' }}</strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout :color="line.grossAmount ? 'info' : 'secondary'">
            <small class="text-muted">Extended Price</small><br />
            <strong class="h4">
              {{ line.grossAmount || '--' }}
            </strong>
          </CCallout>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <!--
      <CRow
        v-if="line.hasRegistrations || line.hasCounterPartyInfo"
        class="pb-4"
      >
        <CCol v-if="line.hasRegistrations">
          <SbxRegistrationsBlock v-bind="line.registrations" />
        </CCol>
        <CCol v-if="line.hasCounterPartyInfo">
          <SbxCounterPartyBlock v-bind="line.counterParty" />
        </CCol>
      </CRow>
      -->
      <CRow v-if="line.hasLineExpectations || line.hasFlexFields" class="pb-4">
        <CCol>
          <fieldset>
            <legend>Line Level Test Expectations</legend>
            <div v-if="line.hasLineExpectations" class="user-element-container">
              <LabeledValue
                v-for="(exp, expIndex) in lineExpectations"
                :key="`${expIndex}_${exp.name}`"
                :label="exp.label"
                :value="
                  exp.name.includes('NONZERO')
                    ? $tc(`taxabilityType.${exp.value.toUpperCase()}`)
                    : exp.value
                "
              />
            </div>
            <div v-else class="text-muted h4 mt-2 ml-1">None</div>
          </fieldset>
        </CCol>
      </CRow>
      <CRow v-if="line.hasFlexFields" class="pb-4">
        <CCol>
          <fieldset>
            <legend>Flexible Code Fields</legend>
            <div
              v-if="line.flexibleFields.codeFields.length > 0"
              class="user-element-container"
            >
              <LabeledValue
                v-for="(cFlex, cFlexIdx) in line.flexibleFields.codeFields"
                :key="`CODE_${cFlexIdx}_${cFlex.fieldId}`"
                :label="cFlex.fieldId"
                :value="cFlex.value"
              />
            </div>
            <div v-else class="text-muted h4 mt-2 ml-1">None</div>
          </fieldset>
        </CCol>
        <CCol>
          <fieldset>
            <legend>Flexible Number Fields</legend>
            <div
              v-if="line.flexibleFields.numberFields.length > 0"
              class="user-element-container"
            >
              <LabeledValue
                v-for="(nFlex, nFlexIdx) in line.flexibleFields.numberFields"
                :key="`NUMBER_${nFlexIdx}_${nFlex.fieldId}`"
                :label="nFlex.fieldId"
                :value="nFlex.value"
              />
            </div>
            <div v-else class="text-muted h4 mt-2 ml-1">None</div>
          </fieldset>
        </CCol>
        <CCol>
          <fieldset>
            <legend>Flexible Date Fields</legend>
            <div
              v-if="flexibleDateFields.length > 0"
              class="user-element-container"
            >
              <LabeledValue
                v-for="(dFlex, dFlexIdx) in flexibleDateFields"
                :key="`DATE_${dFlexIdx}_${dFlex.fieldId}`"
                :label="dFlex.fieldId"
                :value="dFlex.value"
              />
            </div>
            <div v-else class="text-muted h4 mt-2 ml-1">None</div>
          </fieldset>
        </CCol>
      </CRow>

      <CRow v-if="line.addresses.length > 0" class="pb-4">
        <CCol>
          <fieldset>
            <legend>Addresses</legend>
            <CRow class="pt-2">
              <CCol
                v-for="(address, addressIndex) in line.addresses"
                :key="`${line.lineNumber}_${addressIndex}_${address.fieldName}`"
                col="6"
                md="4"
              >
                <DocumentAddress
                  :value="address"
                  :label="$tc(`addressType.${address.addressType}`)"
                />
              </CCol>
            </CRow>
          </fieldset>
        </CCol>
      </CRow>
      <CRow v-if="line.lineAttributes.length > 0" class="pb-4">
        <CCol>
          <fieldset>
            <legend>All Other Line Properties</legend>
            <div class="user-element-container">
              <LabeledValue
                v-for="e in line.lineAttributes"
                :key="e.key"
                :label="e.label"
              >
                <MCheckIcon
                  v-if="e.fieldType === 'BOOLEAN'"
                  :value="e.value"
                  show-false
                />
                <span v-else>
                  {{ e.value }}
                </span>
              </LabeledValue>
            </div>
          </fieldset>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<script>
import DocumentAddress from '@/components/DocumentAddress';
export default {
  name: 'VtxInvoiceLineTab',
  components: {
    DocumentAddress,
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
  },
  computed: {
    lineExpectations() {
      return (this.line.expectations || []).map((exp) => {
        let retval = {
          ...exp,
        };
        // Hack for improperly serialized JSON for right now.
        if (retval.value.startsWith('{') && retval.value.endsWith('}')) {
          retval.value = retval.value.split(',')[0].split('=')[1];
        }
        return retval;
      });
    },
    flexibleDateFields() {
      return (this.line.flexibleFields?.dateFields || []).map((f) => {
        return {
          ...f,

          value: f.value.split('T')[0],
        };
      });
    },
  },
};
</script>
