import { formatCurrency } from '@/utils/format';
import { getDocumentFieldName } from '@/utils/vtxFieldUtils';

const ROW_LINE_PRODUCT_PROPS = [
  ['productCode', 'PRODUCT_CODE'],
  ['productClass', 'PRODUCT_CLASS'],
  ['commoditCode', 'COMMODITY_CODE'],
  ['commodityCodeType', 'COMMODITY_CODE_TYPE'],
  ['materialCode', 'MATERIAL_CODE'],
  ['sku', 'SKU'],
];

const ROW_LINE_PURCH_PRODUCT_PROPMAP = {
  productCode: 'PURCHASE',
  productClass: 'PURCHASE_CLASS',
};

export function getDocumentAddressTypePropertyName(addressType) {
  switch (addressType) {
    case 'DESTINATION':
      return 'DOC_DEST';
    case 'ADMINISTRATIVE_DESTINATION':
      return 'DOC_ADMIN_DEST';
    case 'PHYSICAL_ORIGIN':
      return 'DOC_PHYS_ORIGIN';
    case 'ADMINISTRATIVE_ORIGIN':
      return 'DOC_ADMIN_ORIGIN';
    default:
      return null;
  }
}

const EMPTY_FLEX_FIELDS = {
  empty: true,
  numberFields: [],
  dateFields: [],
  codeFields: [],
};

function numPad(num) {
  let str = `${num}`;
  if (str.length < 2) {
    return `0${num}`;
  } else {
    return str;
  }
}

export function mapFlexibleFields(flexibleFields) {
  if (!flexibleFields) {
    return {
      hasFlexFields: false,
      flexibleFields: EMPTY_FLEX_FIELDS,
    };
  }

  if (flexibleFields.empty === true) {
    return {
      hasFlexFields: false,
      flexibleFields: EMPTY_FLEX_FIELDS,
    };
  }

  let res = {
    hasFlexFields: flexibleFields.empty !== true,
    flexibleFields: {
      ...EMPTY_FLEX_FIELDS,
      empty: flexibleFields.empty === true,
    },
  };

  res.flexibleFields.codeFields = (flexibleFields.codeFields || []).map((f) => {
    return {
      ...f,
      fieldId: numPad(f.fieldId),
    };
  });

  res.flexibleFields.numberFields = (flexibleFields.numberFields || []).map(
    (f) => {
      return {
        ...f,
        fieldId: numPad(f.fieldId),
      };
    },
  );

  res.flexibleFields.dateFields = (flexibleFields.dateFields || []).map((f) => {
    return {
      ...f,
      fieldId: numPad(f.fieldId),
    };
  });
  res.flexibleFields.numberFields.sort((a, b) => {
    if (a.fieldId > b.fieldId) {
      return 1;
    } else if (a.fieldId < b.fieldId) {
      return -1;
    } else {
      return 0;
    }
  });
  res.flexibleFields.codeFields.sort((a, b) => {
    if (a.fieldId > b.fieldId) {
      return 1;
    } else if (a.fieldId < b.fieldId) {
      return -1;
    } else {
      return 0;
    }
  });
  res.flexibleFields.dateFields.sort((a, b) => {
    if (a.fieldId > b.fieldId) {
      return 1;
    } else if (a.fieldId < b.fieldId) {
      return -1;
    } else {
      return 0;
    }
  });

  return res;
}
export function mapLine(doc, line) {
  const rowLine = {
    key: `${doc.id}_${line.lineNumber}`,
    ...line,
    ...mapFlexibleFields(line.flexibleFields),
  };

  rowLine.grossAmount = formatCurrency(doc.currency, rowLine.grossAmount);
  rowLine.products = [];
  if (!!line.product && line.product.empty !== true) {
    const product = line.product;
    rowLine.products = ROW_LINE_PRODUCT_PROPS.map((prop) => {
      const key = prop[0];
      if (product[key]) {
        if (
          product.isPurchase === true &&
          // eslint-disable-next-line no-prototype-builtins
          ROW_LINE_PURCH_PRODUCT_PROPMAP.hasOwnProperty(key)
        ) {
          return {
            type: ROW_LINE_PURCH_PRODUCT_PROPMAP[key],
            code: product[key],
          };
        } else {
          return {
            type: prop[1],
            code: product[key],
          };
        }
      } else {
        return null;
      }
    }).filter((p) => !!p);
  }

  return rowLine;
}

export function mapDocumentAddresses(addresses) {
  return addresses
    .map((a) => {
      const addressProp = getDocumentAddressTypePropertyName(a.addressType);
      if (!addressProp || a.empty === true) {
        return null;
      }
      return {
        addressType: addressProp,
        country: a.country,
        division: a.mainDivision,
        county: a.subDivision,
        address1: a.streetAddress1,
        address2: a.streetAddress2,
        taxAreaId: a.taxAreaId,
        locationCode: a.locationCode,
        zoneType: 'VTX',
      };
    })
    .filter((a) => {
      return !!a;
    });
}

export function mapDocument(doc) {
  const keys = Object.keys(doc.transactionProperties);
  const documentProperties = keys.reduce((acc, key) => {
    if (key !== '_keys' && key !== '_lines') {
      const newKey = getDocumentFieldName(key);
      if (newKey) {
        acc[newKey] = doc.transactionProperties[key];
      }
    }
    return acc;
  }, {});

  const addresses = mapDocumentAddresses(doc.addresses || []).reduce(
    (acc, a) => {
      acc[a.addressType] = a;
      return acc;
    },
    {},
  );

  const row = {
    ...documentProperties,
    ...addresses,
    id: doc.id,
    lineCount: doc.lineCount,
    taxEngineType: doc.taxEngineType || 'VTX',
    documentXML: doc.documentXML,
    company: doc.companyCode,
    division: doc.divisionCode,
    department: doc.departmentCode,
    currency: doc.currencyCode,
    transactionType: doc.transactionType,
    lines: [],
  };

  row.lines = (doc.lines || []).map((line) => {
    return mapLine(row, line);
  });

  return row;
}
