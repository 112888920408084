<template>
  <div class="test-deck-container test-deck-review-container">
    <CCard :class="{ 'is-loading': isLoading }">
      <CCardHeader v-show="!isLoading">
        <div style="display: flex">
          <span class="h2">
            <span
              v-taxengine
              class="mr-2"
              >{{$t(`taxEngineType.${taxEngineType}`)&nbsp;}}</span
            >Test Deck: {{ testDeck.name }}</span
          >
          <span
            class="text-muted"
            style="
              margin-left: auto;
              margin-right: 5px;
              margin-top: auto;
              margin-bottom: auto;
            "
          >
            Created: {{ $format.timestamp(testDeck.createdAt) }}
          </span>

          <MTestDeckRunButton
            :id="`test-deck-review-${testDeckId}`"
            :test-deck-id="testDeckId"
            variant="ghost"
            size="std"
            style="margin-left: 5px; margin-right: 5px"
            @click="() => (showTestDeckRunModal = true)"
          />

          <MTestDeckDownloadButton
            :id="`test-deck-review-${testDeckId}`"
            :test-deck-id="testDeckId"
            variant="ghost"
            size="std"
            style="margin-left: 5px; margin-right: 5px"
          />
          <MTestDeckDeleteButton
            :id="`test-deck-review-${testDeckId}`"
            variant="ghost"
            size="std"
            :test-deck-id="testDeckId"
            style="margin-left: 5px; margin-right: 0"
            @deleted="handleDelete"
          />
        </div>
      </CCardHeader>
      <CCardBody v-if="!isLoading">
        <SbxTestDeckReview
          v-if="taxEngineType === 'SBX'"
          :test-deck-id="testDeckId"
        />
        <VtxTestDeckReview
          v-else-if="taxEngineType === 'VTX'"
          :test-deck-id="testDeckId"
        />
        <AvaTestDeckReview
          v-else-if="taxEngineType === 'AVA'"
          :test-deck-id="testDeckId"
        />
      </CCardBody>
    </CCard>
    <RunTestDeckModal
      v-if="showTestDeckRunModal"
      :show.sync="showTestDeckRunModal"
      :test-deck-id="testDeckId"
    />
  </div>
</template>
<script>
import RunTestDeckModal from '@/components/TestDeck/RunTestDeckModal';
import api from '@/api';
import {
  MTestDeckDeleteButton,
  MTestDeckDownloadButton,
  MTestDeckRunButton,
} from '@/components/TestDeck';
import { SbxTestDeckReview } from './sbx';
import { VtxTestDeckReview } from './vtx';
import { AvaTestDeckReview } from './ava';
export default {
  name: 'TestDeckReview',
  components: {
    MTestDeckDeleteButton,
    MTestDeckDownloadButton,
    MTestDeckRunButton,
    SbxTestDeckReview,
    VtxTestDeckReview,
    AvaTestDeckReview,
    RunTestDeckModal,
  },
  props: {
    testDeckId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      showTestDeckRunModal: false,
      testDeck: {
        name: null,
        createdAt: null,
      },
    };
  },
  computed: {
    taxEngineType() {
      return this.testDeck?.taxEngineType?.toUpperCase() || 'NONE';
    },
  },
  watch: {
    testDeckId(v) {
      if (v) {
        this.fetchTestDeckInfo(v);
      }
    },
  },
  mounted() {
    if (!!this.testDeckId && (!this.isLoading || !!this.testDeck?.name)) {
      this.fetchTestDeckInfo(this.testDeckId);
    }
  },
  methods: {
    fetchTestDeckInfo(testDeckId) {
      this.isLoading = true;
      const self = this;
      self.$log.debug(`Fetching test deck ${testDeckId}`);
      api.tests.decks
        .getTestDeck(testDeckId)
        .then((deck) => {
          self.testDeck = {
            ...deck,
          };
        })
        .catch((err) => {
          self.$log.error(`Error loading test deck ${testDeckId}`, err);
          this.$swal
            .fire({
              title: 'Error',
              text: 'The test deck could not be retrieved',
              icon: 'error',
            })
            .then(() => {
              self.$router.push({ name: 'Testing' });
            });
        })
        .finally(() => {
          self.$nextTick(() => {
            self.isLoading = false;
          });
        });
    },
    handleDelete() {
      this.$router.push({ name: 'Testing' });
    },
  },
};
</script>
