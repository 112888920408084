import hljs from 'highlight.js/lib/core';
import { convertPropertyName, getFieldLabel } from '@/utils/vtxFieldUtils';

const IGNORED_DOC_ATTRS = new Set([
  'DOC_DATE',
  'DOC_ADMIN_DEST',
  'DOC_ADMIN_ORIGIN',
  'DOC_CURRENCY_CODE',
  'DOC_CUSTOMER_CODE',
  'DOC_CUSTOMER_TAX_REG_NUM',
  'DOC_CUSTOMER_CLASS_CODE',
  'DOC_DEST',
  'DOC_IS_BUSINESS',
  'DOC_NUM',
  'DOC_PHYS_ORIGIN',
  'DOC_TRANS_TYPE',
  'COMPANY',
  'DIVISION',
  'DEPARTMENT',
  'DOC_VENDOR_CODE',
  'DOC_VENDOR_TAX_REG_NUM',
  'DOC_VENDOR_CLASS_CODE',
]);

const IGNORED_LINE_ATTRS = new Set([
  'LINE_NUM',
  'LINE_ADMIN_DEST',
  'LINE_ADMIN_ORIGIN',
  'LINE_COMMODITY_CODE',
  'LINE_EXTENDED_PRICE',
  'LINE_DEST',
  // 'LINE_MATERIAL_CODE',
  'LINE_PHYS_ORIGIN',
  'LINE_PRODUCT',
  'LINE_PRODUCT_CLASS',
  'LINE_QUANTITY',
  'LINE_QUANTITY_UOM',
  // 'LINE_USAGE',
  // 'LINE_USAGE_CLASS',
]);

export function highlightXML(xmlText) {
  const cleaned = xmlText
    .split('\n')
    .map((line) => {
      // This is because the Vertex XML formatting gets borked up.
      // Remove 1 leading tab space to shift everything left.
      return line.replace(/^[\t]/, '    ').replace(/^[\s]{4}/, '');
    })
    .reduce((acc, line) => {
      if (acc !== '') {
        return `${acc}\n${line}`;
      } else {
        return line;
      }
    }, '');
  const result = hljs.highlight(cleaned, {
    language: 'xml',
    ignoreIllegals: true,
  });
  return result.value;
}

export function parseTransactionType(value) {
  switch (value.DOC_TRANS_TYPE) {
    case 'SALE':
      return 'Sale';
    case 'PURCHASE':
      return 'Purchase';
    default:
      return value.DOC_TRANS_TYPE || '--';
  }
}

export function parseTaxpayerRole(value) {
  switch (value.DOC_TRANS_TYPE) {
    case 'SALE':
      return 'Seller';
    case 'PURCHASE':
      return 'Buyer';
    default:
      return value.DOC_TRANS_TYPE || '--';
  }
}

export function parseCounteryParty(value) {
  switch (value.DOC_TRANS_TYPE) {
    case 'SALE':
      return {
        type: 'Customer',
        code: value.DOC_CUSTOMER_CODE,
        registration: value.DOC_CUSTOMER_TAX_REG_NUM,
        classCode: value.DOC_CUSTOMER_CLASS_CODE,
        isBusiness: value.DOC_IS_BUSINESS,
        empty: !value.DOC_CUSTOMER_CODE && !value.DOC_CUSTOMER_TAX_REG_NUM,
      };
    case 'PURCHASE':
      return {
        type: 'Vendor',
        code: value.DOC_VENDOR_CODE,
        registration: value.DOC_VENDOR_TAX_REG_NUM,
        classCode: value.DOC_VENDOR_CLASS_CODE,
        isBusiness: value.DOC_IS_BUSINESS,
        empty: !value.DOC_VENDOR_CODE && !value.DOC_VENDOR_TAX_REG_NUM,
      };
  }

  return {
    type: null,
    code: null,
    registration: null,
    classCode: null,
    isBusiness: false,
    empty: true,
  };
}

export function parseRegistrations(value) {
  let registrations = [];
  switch (value.DOC_TRANS_TYPE) {
    case 'SALE':
      if (value.DOC_VENDOR_TAX_REG_NUM) {
        registrations.push({
          type: 'Vendor',
          registration: value.DOC_VENDOR_TAX_REG_NUM,
        });
      }
      break;
    case 'PURCHASE':
      if (value.DOC_CUSTOMER_TAX_REG_NUM) {
        registrations.push({
          type: 'Customer',
          registration: value.DOC_CUSTOMER_TAX_REG_NUM,
        });
      }
      break;
  }
  return registrations;
}

export function parseDocExpectations(value) {
  const retval = {
    hasInvoiceExpectations: false,
    expectedDocTaxability: null,
  };

  if (value['EXPECTED_DOC_ZERO-NONZERO']) {
    retval.hasInvoiceExpectations = true;
    retval.expectedDocTaxability = value['EXPECTED_DOC_ZERO-NONZERO'];
  }

  return retval;
}

export function parseDocAttributes(value) {
  return Object.keys(value)
    .filter((key) => {
      return key.startsWith('DOC_') && !IGNORED_DOC_ATTRS.has(key);
    })
    .map((key) => {
      return {
        key,
        label: getFieldLabel(key),
        value: value[key],
      };
    });
}

export function parseDocAddresses(value) {
  const fields = [
    'DOC_PHYS_ORIGIN',
    'DOC_DEST',
    'DOC_ADMIN_DEST',
    'DOC_ADMIN_ORIGIN',
  ];
  const mapped = fields
    .map((key, idx) => {
      let a = value[key];
      if (!a) {
        return null;
      }
      return {
        ...a,
        key: `${value.DOC_NUM}_${key}_${idx}`,
        fieldName: key,
      };
    })
    .filter((a) => {
      return !!a;
    });
  return {
    hasDocumentAddresses: mapped.length > 0,
    documentAddresses: mapped,
  };
}

export function convertLineProps(properties) {
  if (!properties) {
    return {};
  }

  return Object.keys(properties)
    .map((key) => {
      const newKey = convertPropertyName(key);
      if (newKey) {
        return [key, newKey];
      } else {
        return null;
      }
    })
    .filter((keys) => !!keys)
    .map((keys) => {
      const value = properties[keys[0]];
      return [keys[1], value];
    })
    .reduce((prv, pair) => {
      prv[pair[0]] = pair[1];
      return prv;
    }, {});
}

function parseLineExpectations(expectations) {
  return (expectations || []).map((exp) => {
    return {
      ...exp,
      label: getFieldLabel(convertPropertyName(exp.name))?.replace('Exp. ', ''),
    };
  });
}

function parseLineAttributes(properties) {
  return Object.keys(properties)
    .filter((key) => {
      return (
        key.startsWith('LINE_') &&
        !key.startsWith('EXPECTED_') &&
        !key.startsWith('LINE_FLEX_FIELD_') &&
        !key.startsWith('LINE_DEST_') &&
        !key.startsWith('LINE_ADMIN_') &&
        !key.startsWith('LINE_PHYS_ORIGIN') &&
        !IGNORED_LINE_ATTRS.has(key)
      );
    })
    .map((key) => {
      return {
        key,
        label: getFieldLabel(key),
        value: properties[key],
      };
    });
}

function parseLineAddresses(addresses) {
  return addresses.map((a) => {
    let addressType = a.addressType;
    switch (a.addressType) {
      case 'DESTINATION':
        addressType = 'LINE_DEST';
        break;
      case 'ADMINISTRATIVE_DESTINATION':
        addressType = 'LINE_ADMIN_DEST';
        break;
      case 'ADMINISTRATIVE_ORIGIN':
        addressType = 'LINE_ADMIN_ORIGIN';
        break;
      case 'PHYSICAL_ORIGIN':
        addressType = 'LINE_PHYS_ORIGIN';
        break;
    }

    return {
      ...a,
      addressType,
    };
  });
}
function parseLine(value) {
  const lineProps = convertLineProps(value.lineProperties);
  const lineAttributes = parseLineAttributes(lineProps);

  let line = {
    ...value,
    lineProperties: lineProps,
    lineAttributes,
    registrations: [],
    hasLineAddresses: (value.addresses || []).length > 0,
    hasLineExpectations: (value.expectations || []).length > 0,
    expectations: parseLineExpectations(value.expectations),
    addresses: parseLineAddresses(value.addresses || []),
  };
  delete line['product'];
  delete line['activeFields'];

  return line;
}

export function parseValue(value) {
  if (!value) {
    return {
      documentNumber: null,
      company: null,
      division: null,
      department: null,
      transactionType: null,
      taxpayerRole: null,
      documentDate: null,
      currencyCode: null,
      counterparty: {
        type: null,
        code: null,
        registration: null,
        empty: true,
      },
      documentLines: [],
      registrations: [],
      documentAttributes: [],
      hasDocumentAddresses: false,
      documentAddresses: [],
      lineCount: 0,
      documentXML: {
        plain: null,
        highlight: null,
      },
    };
  }

  const transactionType = parseTransactionType(value);
  const taxpayerRole = parseTaxpayerRole(value);

  let doc = {
    ...value,
    documentNumber: value.DOC_NUM,
    company: value.COMPANY,
    division: value.DIVISION,
    department: value.DEPARTMENT,
    transactionType,
    taxpayerRole,
    documentDate: value.DOC_DATE || '--',
    currencyCode: value.DOC_CURRENCY_CODE || '--',
    counterparty: parseCounteryParty(value),
    registrations: parseRegistrations(value),
    documentAttributes: parseDocAttributes(value),
    ...parseDocExpectations(value),
    ...parseDocAddresses(value),
    documentXML: {
      plain: value.documentXML,
      highlight: highlightXML(value.documentXML),
    },
    documentLines: (value.lines || []).map((line) => parseLine(line)),
    lineCount: (value.lines || []).length,
  };

  delete doc['lines'];

  return doc;
}
