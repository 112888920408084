<template>
  <CRow class="test-deck-review-callout-row">
    <CCol col="6" md="3">
      <CCallout color="secondary">
        <small id="test-deck-review-filename-callout-label" class="text-muted"
          >Original Filename</small
        ><br />
        <strong
          id="test-deck-review-filename-callout-value"
          class="h4"
          aria-labelledby="test-deck-review-filename-callout-label"
          >{{ testDeck.filename }}</strong
        >
      </CCallout>
    </CCol>
    <CCol col="6" md="3">
      <CCallout color="secondary">
        <small
          id="test-deck-review-description-callout-label"
          class="text-muted"
          >Description</small
        ><br />
        <strong
          id="test-deck-review-description-callout-value"
          aria-labelledby="test-deck-review-description-callout-label"
          :class="{
            h4: !hasTestDeckDescription || testDeckDescription.length < 128,
          }"
          >{{ hasTestDeckDescription ? testDeckDescription : '--' }}</strong
        >
      </CCallout>
    </CCol>
    <CCol col="6" md="3">
      <CCallout color="secondary">
        <small id="test-deck-review-item-count-callout-label" class="text-muted"
          >Total Test Transactions</small
        ><br />
        <strong
          id="test-deck-review-item-count-callout-value"
          aria-labelledby="test-deck-review-item-count-callout-label"
          class="h4"
          >{{ $format.number(testDeck.itemCount) }}</strong
        >
      </CCallout>
    </CCol>
    <CCol col="6" md="3">
      <CCallout color="secondary">
        <small id="test-deck-review-exp-count-callout-label" class="text-muted"
          >Distinct Test Expectations</small
        ><br />
        <div>
          <strong
            id="test-deck-review-exp-count-callout-value"
            aria-labelledby="test-deck-review-exp-count-callout-label"
            class="h4"
            >{{ $format.number(testExpectations) }}</strong
          >
        </div>
      </CCallout>
    </CCol>
  </CRow>
</template>
<script>
export default {
  name: 'TestDeckCalloutRow',
  props: {
    testDeck: {
      type: Object,
      required: true,
    },
    testExpectations: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    testDeckDescription() {
      return this.testDeck?.description?.trim() || '';
    },
    hasTestDeckDescription() {
      return !!this.testDeckDescription && this.testDeckDescription.length > 0;
    },
  },
};
</script>
