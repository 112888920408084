<template>
  <CModal
    class="sbx-invoice-details-modal modal-xxl"
    :show.sync="modalShow"
    size="xl"
    centered
    :close-on-backdrop="false"
  >
    <template #header>
      <h2>Invoice: {{ invoiceNumber }}</h2>
    </template>
    <CCard class="document-details-card sbx-document-details-card">
      <CCardBody>
        <CRow>
          <CCol>
            <CCallout color="primary">
              <small class="text-muted">External Company ID</small><br />
              <strong class="h4">{{ externalCompanyId }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout :color="companyName ? 'primary' : 'secondary'">
              <small class="text-muted">Company Name</small><br />
              <span class="h4" :class="{ 'text-muted': !companyName }">
                {{ companyName || '--' }}
              </span>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout color="primary">
              <small class="text-muted">Company Role</small><br />
              <strong class="h4">Seller</strong>
            </CCallout>
          </CCol>
        </CRow>
        <CRow class="pt-2">
          <CCol col="4">
            <fieldset class="d-inline-flex">
              <legend>General</legend>
              <LabeledValue label="Invoice Date">
                {{ invoiceDate }}
              </LabeledValue>
              <LabeledValue label="Currency Code">
                {{ currencyCode }}
              </LabeledValue>

              <LabeledValue label="Transaction Type">
                {{ transactionType }}
              </LabeledValue>
              <LabeledValue v-if="titleTransfer" label="Title Transfer">
                {{ titleTransfer }}
              </LabeledValue>
            </fieldset>
          </CCol>
          <CCol>
            <SbxRegistrationsBlock v-bind="registrations" />
          </CCol>
          <CCol>
            <SbxCounterPartyBlock v-bind="counterParty" />
          </CCol>
        </CRow>
        <CTabs :active-tab.sync="activeTab" class="pt-5">
          <CTab title="Invoice" class="invoice-details-tab">
            <CCard>
              <CCardBody>
                <CRow
                  v-if="hasInvoiceExpectations || hasInvoiceUserElements"
                  class="pb-4"
                >
                  <CCol col="6">
                    <fieldset class="d-inline-flex">
                      <legend>Invoice Level Test Expectations</legend>
                      <div
                        v-if="hasInvoiceExpectations"
                        class="user-element-container"
                      >
                        <LabeledValue
                          label="Taxability"
                          :empty="!expectedInvoiceTaxability"
                        >
                          {{ expectedInvoiceTaxability || '--' }}
                        </LabeledValue>
                        <LabeledValue
                          label="Tax Invoice Description"
                          :empty="!expectedTaxInvoiceDescription"
                        >
                          {{ expectedTaxInvoiceDescription || '--' }}
                        </LabeledValue>
                      </div>
                      <div v-else class="text-muted h4 mt-2 ml-1">
                        None <small class="ml-1">(see lines)</small>
                      </div>
                    </fieldset>
                  </CCol>
                  <CCol v-if="hasInvoiceUserElements" col="6">
                    <fieldset>
                      <legend>User Elements</legend>
                      <div class="user-element-container">
                        <LabeledValue
                          v-for="e in invoiceUserElements"
                          :key="e.key"
                          :label="e.label"
                          :value="e.value"
                        />
                      </div>
                    </fieldset>
                  </CCol>
                </CRow>
                <CRow v-if="hasInvoiceAddresses" class="pb-4">
                  <CCol>
                    <fieldset>
                      <legend>
                        Addresses
                        <small class="text-muted">
                          ({{ invoiceAddresses.length }})
                        </small>
                      </legend>
                      <CRow>
                        <CCol
                          v-for="invAddress in invoiceAddresses"
                          :key="invAddress.key"
                          col="6"
                          md="4"
                          lg="3"
                        >
                          <DocumentAddress
                            :value="invAddress"
                            :label="
                              $t(
                                `addressType.${invAddress.fieldName
                                  .toLowerCase()
                                  .replace('invoice.', '')}`,
                              )
                            "
                            class="pt-2"
                          />
                        </CCol>
                      </CRow>
                    </fieldset>
                  </CCol>
                </CRow>
                <CRow v-if="documentAttributes.length > 0" class="pb-4">
                  <CCol>
                    <fieldset>
                      <legend>All Other Invoice Attributes</legend>
                      <div
                        v-if="documentAttributes.length > 0"
                        class="user-element-container"
                      >
                        <LabeledValue
                          v-for="e in documentAttributes"
                          :key="e.key"
                          :label="e.label"
                        >
                          <MCheckIcon
                            v-if="e.fieldType === 'BOOLEAN'"
                            :value="e.value"
                            show-false
                          />
                          <span v-else>
                            {{ e.value }}
                          </span>
                        </LabeledValue>
                      </div>
                      <div v-else class="text-muted h4 mt-2 ml-1">None</div>
                    </fieldset>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CTab>
          <CTab :title="`Lines (${lineCount})`" class="line-details-tab">
            <CTabs
              :active-tab="activeLineTab"
              class="pt-4"
              variant="pills"
              :vertical="{
                navs: 'col-xs-2 col-md-1',
                content: 'col-xs-10 col-md-11',
              }"
            >
              <CTab
                v-for="(line, index) in documentLines"
                :key="`${index}_${line.lineNumber}_${line.key}`"
                :title="`Line ${line.lineNumber}`"
              >
                <SbxInvoiceLineTab :line="line" />
              </CTab>
            </CTabs>
          </CTab>
          <!--
          <CTab title="XML" class="xml-tab">
            <CCard>
              <CCardBody>
                <CRow>
                  <CCol class="d-flex"> </CCol>
                </CRow>
                <CRow>
                  <CCol style="position: relative">
                    <MClipboardButton
                      id="btn-sbx-invoice-details-xml-copy"
                      style="
                        margin-right: 1rem;
                        position: absolute;
                        top: 5px;
                        right: 25px;
                      "
                      @click="copyTextToClipBoard"
                    />
                    <pre
                      :id="xmlDetailsId"
                      class="code hljs xml"
                      v-html="documentXML.highlight"
                    ></pre>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CTab>
          -->
        </CTabs>
      </CCardBody>
    </CCard>
    <template #footer>
      <CButton
        id="btn-sbx-invoice-details-close"
        color="primary"
        @click="modalShow = false"
        >Close</CButton
      >
    </template>
  </CModal>
</template>
<script>
import DocumentAddress from '@/components/DocumentAddress';
import SbxRegistrationsBlock from '@/views/testing/decks/sbx/_components/SbxRegistrationsBlock';
import SbxCounterPartyBlock from '@/views/testing/decks/sbx/_components/SbxCounterPartyBlock';
import SbxInvoiceLineTab from '@/views/testing/decks/sbx/_components/SbxInvoiceLineTab';
// import { MClipboardButton } from '@/components/Buttons';

import { parseValue /*, highlightXML*/ } from './SbxInvoiceDetailsUtils';

export default {
  name: 'SbxInvoiceDetailsModal',
  components: {
    SbxInvoiceLineTab,
    DocumentAddress,
    SbxRegistrationsBlock,
    SbxCounterPartyBlock,
    // MClipboardButton,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      activeLineTab: 0,
      registrations: [],
      counterParty: null,
      invoiceNumber: null,
      companyName: null,
      externalCompanyId: null,
      companyRole: null,
      transactionType: null,
      invoiceDate: null,
      titleTransfer: null,
      currencyCode: null,
      invoiceUserElements: [],
      hasOtherElements: false,
      invoiceAddresses: [],
      documentAttributes: [],
      documentLines: [],
      attributeFields: [
        {
          key: 'label',
          label: 'Name',
        },
        {
          key: 'fieldPath',
          label: 'Path',
          hidden: true,
        },
        {
          key: 'value',
          label: 'Value',
          filter: false,
          sorter: false,
        },
      ],
      documentXML: '',
    };
  },
  computed: {
    // xmlDetailsId() {
    //   return `sbx-xml-details_${this.value.id}`;
    // },
    modalShow: {
      get() {
        return this.show === true;
      },
      set(v) {
        this.$emit('update:show', v);
      },
    },
    hasRegistrations() {
      return !!(
        this.registrations?.buyer ||
        this.registrations?.seller ||
        this.registrations?.middleman
      );
    },
    hasInvoiceAddresses() {
      return this.invoiceAddresses.length > 0;
    },
    expectedInvoiceTaxability() {
      return this.value['EXPECTED.INVOICE.TAXABILITY'];
    },
    expectedTaxInvoiceDescription() {
      return this.value['EXPECTED.INVOICE.TAX_INVOICE_DESC'];
    },
    lineCount() {
      return (this.value._lines || []).length;
    },

    hasInvoiceUserElements() {
      return this.invoiceUserElements && this.invoiceUserElements.length > 0;
    },
    hasInvoiceExpectations() {
      return (
        this.expectedInvoiceTaxability || this.expectedTaxInvoiceDescription
      );
    },

    hasCounterPartyInfo() {
      return !!this.counterParty;
    },
  },
  mounted() {
    const data = parseValue(this.value);
    this.registrations = data.registrations;
    this.counterParty = data.counterParty;
    this.companyName = data.companyName;
    this.invoiceNumber = data.invoiceNumber;
    this.externalCompanyId = data.externalCompanyId;
    this.companyRole = data.companyRole;
    this.transactionType = data.transactionType;
    this.invoiceDate = data.invoiceDate;
    this.titleTransfer = data.titleTransfer;
    this.currencyCode = data.currencyCode;
    this.invoiceUserElements = data.invoiceUserElements;
    this.documentAttributes = data.documentAttributes;
    this.invoiceAddresses = data.invoiceAddresses;
    this.documentLines = data.documentLines;
    // this.documentXML = Object.freeze({
    //   plain: '<none />', //data.documentXML,
    //   highlight: highlightXML('<none />'), //data.documentXML),
    // });
  },
  methods: {
    copyTextToClipBoard() {
      const copyText = document.createElement('textarea');
      const parent = document.getElementById(this.xmlDetailsId);
      copyText.value = this.documentXML.plain;
      copyText.setAttribute('readonly', 'true');
      copyText.style = { visibility: 'hidden' };
      parent.appendChild(copyText);
      copyText.select();
      navigator.clipboard.writeText(copyText.value);
      parent.removeChild(copyText);
    },
  },
};
</script>
<style lang="scss">
.document-details-card {
  padding-bottom: 0;
  margin-bottom: 0;
}

.tab-pane {
  .card {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@media (max-height: 900px) {
  .code.hljs {
    width: 100%;
    height: 250px;
    overflow-y: auto;
  }

  .tab-pane {
    height: 400px;
    overflow-y: auto;

    .tab-pane {
      height: unset;
      overflow: unset;
    }
  }
}

@media (min-height: 901px) {
  .code.hljs {
    width: 100%;
    height: 350px;
    overflow-y: auto;
  }

  .tab-pane {
    height: 500px;
    overflow-y: auto;

    .tab-pane {
      height: unset;
    }
  }
}

.user-element-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
}

.document-details-card {
  .c-callout {
    margin-top: 0;
  }
  .nav-pills .nav-link {
    color: #39f;
  }
  .nav-pills .nav-link.active {
    color: #fff;
    background-color: #39f;
  }

  fieldset {
    legend {
      font-size: 1rem;
      font-weight: 600;
      padding: 0;
      margin: 0;
    }
    & > div {
      margin-left: -0.25rem;
    }

    & > div.row {
      margin-left: -15px;
    }

    & > div.row > div > .document-address-container {
      margin-left: 0;
    }
  }
  .m-table-wrapper {
    height: 200px !important;
  }
}
</style>
