<template>
  <div class="test-deck-review-body sbx-test-deck-review-body">
    <TestDeckCalloutRow
      :test-deck="testDeck"
      :test-expectations="
        transactionFields.filter((i) => i.startsWith('EXPECTED')).length
      "
    />
    <CRow class="mt-4">
      <CCol>
        <CCard>
          <CCardHeader class="d-flex">
            <div class="h4 card-title">
              <CIcon name="cil-globe-alt" class="mr-2" size="lg" />Testing Map
            </div>
            <div style="margin-left: auto; margin-right: 0">
              <CToggler
                in-header
                @click.prevent="
                  () => {
                    if (!isLoading) {
                      if (showMap) {
                        clearMapToolTips();
                      }
                      showMap = !showMap;
                    }
                  }
                "
              >
                <CIcon
                  :name="showMap ? 'cis-chevron-bottom' : 'cis-chevron-left'"
                />
              </CToggler>
            </div>
          </CCardHeader>
          <CCollapse :show.sync="showMap">
            <CCardBody class="map-wrapper">
              <div id="test-deck-map" class="map-container"></div>
            </CCardBody>
          </CCollapse>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCard class="filters-card">
          <CCardHeader>
            <CRow>
              <CCol col="4">
                <h4 class="card-title">
                  <CIcon name="cil-filter" class="mr-2" size="lg" />Filters
                </h4>
              </CCol>
              <CCol col="8">
                <div class="d-flex">
                  <div style="margin-left: auto; margin-right: 0">
                    <template v-if="!showFilters">
                      <CBadge
                        v-for="label in filterLabels"
                        :id="`bdg-filter-label-badge_${label.filterKey}`"
                        :key="label.filterKey"
                        class="h5 ml-2 filter-label-badge"
                        :color="label.color"
                        >{{ label.label
                        }}<small v-if="label.labelSubText">{{
                          label.labelSubText
                        }}</small>
                        <CIcon
                          :id="`btn-filter-label-badge-close_${label.filterKey}`"
                          name="cil-x-circle"
                          class="ml-2 clickable badge-close"
                          title="Remove Filter"
                          role="button"
                          @click.native="removeFilter(label.filterKey)"
                        />
                      </CBadge>
                    </template>
                    <CToggler
                      id="tgl-test-deck-review-filters"
                      class="ml-2"
                      in-header
                      @click.prevent="
                        () => {
                          showFilters = !showFilters;
                        }
                      "
                    >
                      <CIcon
                        :name="
                          showFilters
                            ? 'cis-chevron-bottom'
                            : 'cis-chevron-left'
                        "
                      />
                    </CToggler>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCollapse :show.sync="showFilters">
            <CCardBody>
              <CRow>
                <CCol col="4">
                  <label for="test-deck-filter-ship-to">Ship To</label>
                  <MGeoFilter
                    id="test-deck-filter-ship-to"
                    :options="shipToGeos"
                    :value.sync="selectedShipToGeos"
                  />
                </CCol>
                <CCol col="2" class="text-center">
                  <div>
                    <label for="test-deck-filter-logic">Geo Filter Logic</label>
                  </div>
                  <CButton
                    id="test-deck-filter-logic"
                    color="info"
                    @click="toggleGeoLogic"
                    >{{ geoLogic }}</CButton
                  >
                  <div class="text-note mt-1">
                    {{
                      geoLogic === 'AND'
                        ? 'Only include Ship To from Ship From'
                        : 'Include Ship To or Ship From'
                    }}
                  </div>
                </CCol>
                <CCol col="4">
                  <label for="test-deck-filter-ship-from">Ship From</label>
                  <MGeoFilter
                    id="test-deck-filter-ship-from"
                    :options="shipFromGeos"
                    :value.sync="selectedShipFromGeos"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="4">
                  <label for="test-deck-filter-transaction-type"
                    >Transaction Type</label
                  >
                  <SbxTransactionTypePicker
                    id="test-deck-filter-transaction-type"
                    :value.sync="selectedTransactionTypes"
                    :limit="deckTransactionTypes"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCollapse>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <MDataTable
          id="tbl-test-deck-review"
          :fields="fields"
          fixed
          striped
          :loading="isLoading"
          :items="content"
          :table-filter="{ external: true, lazy: true }"
          :column-filter="false"
          :sorter="{ external: true }"
          :sorter-value.sync="columnSorter"
          :table-filter-value.sync="tableFilterValue"
          :pagination="false"
          :per-page="pageSize"
        >
          <template #INVOICE_SHIP_TO="{ item }">
            <ZoneAddress :value="item['INVOICE_SHIP_TO']" />
          </template>
          <template #INVOICE_BILL_TO="{ item }">
            <ZoneAddress :value="item['INVOICE_BILL_TO']" />
          </template>
          <template #INVOICE_SHIP_FROM="{ item }">
            <ZoneAddress :value="item['INVOICE_SHIP_FROM']" />
          </template>
          <template #INVOICE_MIDDLEMAN="{ item }">
            <ZoneAddress :value="item['INVOICE_MIDDLEMAN']" />
          </template>
          <template #INVOICE_SUPPLY="{ item }">
            <ZoneAddress :value="item['INVOICE_SUPPLY']" />
          </template>
          <template #INVOICE_ORDER_ACCEPTANCE="{ item }">
            <ZoneAddress :value="item['INVOICE_ORDER_ACCEPTANCE']" />
          </template>
          <template #INVOICE_ORDER_ORIGIN="{ item }">
            <ZoneAddress :value="item['INVOICE_ORDER_ORIGIN']" />
          </template>
          <template #INVOICE_BUYER_PRIMARY="{ item }">
            <ZoneAddress :value="item['INVOICE_BUYER_PRIMARY']" />
          </template>
          <template #INVOICE_SELLER_PRIMARY="{ item }">
            <ZoneAddress :value="item['INVOICE_SELLER_PRIMARY']" />
          </template>

          <template #table-controls>
            <MTablePaginator
              id="pg-test-deck-review-paginator"
              :page.sync="pageNumber"
              :per-page.sync="pageSize"
              :total-rows="totalCount"
              :page-count="totalPages"
              :filtered-rows="content.length"
              :filtering="false"
            >
            </MTablePaginator>
          </template>
          <template #details="{ item }">
            <div class="invoice-line-details-wrapper">
              <CCard
                accent-color="info"
                align="left"
                class="invoice-line-details-card"
              >
                <CCardHeader>
                  <div class="d-flex">
                    <MDetailsButton
                      :id="`btn-show-invoice-details_${item.id}`"
                      @click="doShowInvoiceDetails(item)"
                    />
                    <span
                      class="h4 ml-2"
                      style="margin-top: auto; margin-bottom: auto"
                      >&nbsp;Line Details:
                      {{ item['INVOICE.INVOICE_NUMBER'] }}</span
                    >
                    <span
                      class="text-muted"
                      style="
                        margin-left: 5px;
                        margin-top: auto;
                        margin-bottom: auto;
                      "
                      >({{ item._lines.length }}
                      {{ $plural('entity.line', item._lines.length) }})</span
                    >
                  </div>
                </CCardHeader>
                <CCardBody class="invoice-line-details-container">
                  <SbxInvoiceLine
                    v-for="line in item._lines"
                    :key="line.key"
                    :value="line"
                  />
                </CCardBody>
              </CCard>
            </div>
          </template>
        </MDataTable>
      </CCol>
    </CRow>

    <SbxInvoiceDetailsModal
      v-if="showInvoiceDetails"
      id="mod-sbx-invoice-details"
      :value="selectedInvoice"
      :show.sync="showInvoiceDetails"
    />
    <!-- <RunTestDeckModal
      v-if="showTestDeckRunModal"
      :show.sync="showTestDeckRunModal"
      :test-deck-id="testDeckId"
    /> -->
  </div>
</template>
<script>
import { mapCountryDataForSvg } from '@/utils/parsing/testDeck';
import TestDeckCalloutRow from '@/views/testing/decks/TestDeckCalloutRow';
import {
  createSvgMap,
  getSortColumnName,
  mapTransaction,
} from './SbxTestDeckReview.js';
// import RunTestDeckModal from '@/components/TestDeck/RunTestDeckModal';
import api from '@/api';
import MDataTable from '@/components/MDataTable/MDataTable';
import MTablePaginator from '@/components/MDataTable/MTablePaginator';
import ZoneAddress from '@/views/testing/_components/ZoneAddress';
import SbxInvoiceLine from '@/views/testing/decks/sbx/_components/SbxInvoiceLine';
import SbxInvoiceDetailsModal from '@/views/testing/decks/sbx/_components/SbxInvoiceDetailsModal';
import { MGeoFilter } from '@/components/form/MGeoPicker';
import SbxTransactionTypePicker from '@/components/form/SbxTransactionTypePicker';
import { MDetailsButton } from '@/components/Buttons';
// import {
//   MTestDeckDeleteButton,
//   MTestDeckDownloadButton,
//   MTestDeckRunButton,
// } from '@/components/TestDeck';

import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import {
  SBX_TRANSACTION_FIELDS,
  SBX_REWRITE_FIELDS,
  SBX_FORMAT_FIELDS,
  SBX_SORTABLE_FIELDS,
} from '@/utils/sbxFieldUtils';

export default {
  name: 'SbxTestDeckReview',
  components: {
    MDataTable,
    MTablePaginator,
    ZoneAddress,
    SbxInvoiceLine,
    MGeoFilter,
    MDetailsButton,
    TestDeckCalloutRow,
    // MTestDeckDeleteButton,
    // MTestDeckDownloadButton,
    SbxInvoiceDetailsModal,
    SbxTransactionTypePicker,
    // RunTestDeckModal,
    // MTestDeckRunButton,
  },
  props: {
    testDeckId: {
      type: String,
      required: true,
    },
  },
  $svgMap: null,
  data() {
    return {
      showTestDeckRunModal: false,
      showFilters: false,
      taxEngineType: 'SBX',
      isLoading: false,
      page: 1,
      size: 50,
      content: [],
      totalCount: 0,
      totalPages: 1,
      transactionFields: [],
      internalTableFilterValue: null,
      internalColumnFilterValue: null,
      internalSorter: {
        column: 'invoice.invoice_number',
        asc: true,
      },
      shipToGeos: [],
      shipFromGeos: [],
      filters: {
        shipFrom: null,
        shipTo: null,
        transactionTypes: null,
        companyRoles: null,
        currencies: null,
      },
      testDeck: {
        name: null,
        filename: null,
        createdAt: null,
        itemCount: 0,
        description: null,
      },
      showMap: false,
      mapCountries: {},
      selectedInvoice: null,
      showInvoiceDetails: false,
      geoLogic: 'AND',
      deckCurrencies: [],
      deckTransactionTypes: [],
    };
  },
  computed: {
    // hasTestDeckDescription() {
    //   return !!this.testDeckDescription && this.testDeckDescription.length > 0;
    // },
    // testDeckDescription() {
    //   return this.testDeck?.description?.trim();
    // },
    transactionTypeFilter: {
      get() {
        return this.filters.transactionTypes;
      },
      set(v) {
        this.filters.transactionTypes = v;
        this.fetchTestDeck(this.testDeckId, {
          ...this.queryArgs,
          page: 0,
          transactionType: v,
        });
      },
    },
    selectedShipFromGeos: {
      get() {
        return this.filters.shipFrom;
      },
      set(v) {
        this.filters.shipFrom = v;
        this.fetchTestDeck(this.testDeckId, {
          ...this.queryArgs,
          page: 0,
          shipFrom: v,
        });
      },
    },
    selectedShipToGeos: {
      get() {
        return this.filters.shipTo;
      },
      set(v) {
        this.filters.shipTo = v;
        this.fetchTestDeck(this.testDeckId, {
          ...this.queryArgs,
          page: 0,
          shipTo: v,
        });
      },
    },
    selectedTransactionTypes: {
      get() {
        return this.filters.transactionTypes;
      },
      set(v) {
        this.filters.transactionTypes = v;
        this.fetchTestDeck(this.testDeckId, {
          ...this.queryArgs,
          page: 0,
          transactionType: v,
        });
      },
    },
    selectedCompanyRoles: {
      get() {
        return this.filters.companyRoles;
      },
      set(v) {
        this.filters.companyRoles = v;
        this.fetchTestDeck(this.testDeckId, {
          ...this.queryArgs,
          page: 0,
          companyRole: v,
        });
      },
    },
    fields() {
      const fieldSet = new Set(this.transactionFields);
      return SBX_TRANSACTION_FIELDS.map((field) => {
        return {
          ...field,
          hidden:
            field.key.startsWith('LINE') ||
            field.key.startsWith('EXPECTED.LINE') ||
            field.key.startsWith('RESULTS.LINE') ||
            !fieldSet.has(field.key),
          sorter: SBX_SORTABLE_FIELDS.has(field.key),
        };
      }).filter((field) => !field.hidden);
    },
    pageNumber: {
      get() {
        return this.page;
      },
      set(v) {
        if (this.isLoading) {
          return;
        }

        let pageNumber = v;
        if (!v || v < 1) {
          pageNumber = 1;
        }

        if (pageNumber !== this.page) {
          this.fetchTestDeck(this.testDeckId, {
            ...this.queryArgs,
            page: pageNumber - 1,
          });
        }

        this.page = v;
      },
    },
    pageSize: {
      get() {
        return this.size;
      },
      set(v) {
        let pageSize = v;
        if (!v || v < 5) {
          pageSize = 5;
        }

        if (this.size !== pageSize) {
          this.fetchTestDeck(this.testDeckId, {
            ...this.queryArgs,
            size: pageSize,
            page: 0,
          });
        }
        this.size = v;
      },
    },
    queryArgs() {
      return {
        page: this.pageNumber - 1,
        size: this.pageSize,
        queryText: this.tableFilterValue?.trim(),
        sort: this.calculatedSort,
        shipTo: this.filters.shipTo || [],
        shipFrom: this.filters.shipFrom || [],
        transactionType: this.filters.transactionTypes || [],
        currencyCode: [], // TODO:
        companyRole: this.filters.companyRoles || [],
        geoLogic: this.geoLogic,
      };
    },
    filterLabels() {
      const tags = [];
      if (this.filters.shipTo && this.filters.shipTo.length > 0) {
        tags.push({
          label: 'Ship To',
          labelSubText: `(${this.filters.shipTo.length})`,
          color: 'info',
          filterKey: 'shipTo',
        });
      }
      if (this.filters.shipFrom && this.filters.shipFrom.length > 0) {
        tags.push({
          label: `Ship From`,
          labelSubText: `(${this.filters.shipFrom.length})`,
          color: 'info',
          filterKey: 'shipFrom',
        });
      }

      if (
        this.filters.transactionTypes &&
        this.filters.transactionTypes.length > 0
      ) {
        tags.push({
          label: 'Transaction Type',
          labelSubText: `(${this.filters.transactionTypes.length})`,
          color: 'info',
          filterKey: 'transactionTypes',
        });
      }
      return tags;
    },
    tableFilterValue: {
      get() {
        return this.internalTableFilterValue;
      },
      set(v) {
        this.internalTableFilterValue = v;
        let qa = {
          ...this.queryArgs,
          page: 0,
          queryText: v?.trim(),
        };
        this.fetchTestDeck(this.testDeckId, qa);
      },
    },
    calculatedSort() {
      const column = getSortColumnName(this.columnSorter.column);
      return `${column} ${this.columnSorter.asc ? 'ASC' : 'DESC'}`;
    },
    columnSorter: {
      get() {
        return this.internalSorter;
      },
      set(v) {
        let sort;
        if (!v) {
          sort = {
            external: true,
            resetable: false,
            column: 'INVOICE.INVOICE_NUMBER',
            asc: true,
          };
        } else {
          let column = v.column || 'INVOICE.INVOICE_NUMBER';
          let asc = v.asc === false ? false : true;

          sort = {
            external: true,
            resetable: false,
            column,
            asc,
          };
        }

        this.internalSorter = sort;

        const qa = {
          ...this.queryArgs,
          page: 0,
          sort: `${getSortColumnName(sort.column)} ${
            sort.asc ? 'ASC' : 'DESC'
          }`,
        };

        this.fetchTestDeck(this.testDeckId, qa);
      },
    },
  },
  watch: {
    testDeckId(v) {
      this.fetchTestDeck(v, this.queryArgs);
    },
    showMap(v) {
      if (v && !this.$svgMap) {
        this.$nextTick(() => {
          this.$svgMap = createSvgMap(this.mapCountries);
          this.generateClickablePaths();
        });
      }
    },
  },
  mounted() {
    const self = this;
    this.fetchTestDeck(this.testDeckId, this.queryArgs).then((data) => {
      const countries = mapCountryDataForSvg(
        data.testDeck.metadata?.metrics?.shipTo || {},
        data.testDeck.metadata?.metrics?.shipFrom || {},
      );
      self.mapCountries = Object.freeze(countries);
      if (self.showMap && !self.$svgMap) {
        self.$svgMap = createSvgMap(countries);
        this.generateClickablePaths();
      }
    });
  },
  methods: {
    clearMapToolTips() {
      this.$nextTick(() => {
        Array.from(document.getElementsByClassName('svgMap-tooltip')).forEach(
          (elm) => {
            elm.classList.remove('svgMap-active');
          },
        );
      });
    },
    toggleGeoLogic() {
      const logic = this.geoLogic === 'AND' ? 'OR' : 'AND';
      this.geoLogic = logic;
      if (
        this.selectedShipToGeos?.length > 0 ||
        this.selectedShipFromGeos?.length > 0
      ) {
        this.fetchTestDeck(this.testDeckId, {
          ...this.queryArgs,
          geoLogic: logic,
          page: 0,
        });
      }
    },
    removeFilter(filterKey) {
      switch (filterKey) {
        case 'shipTo':
          this.selectedShipToGeos = [];
          break;
        case 'shipFrom':
          this.selectedShipFromGeos = [];
          break;
      }
    },
    handleCountryClick(isoCode) {
      let shipTo = [];
      let shipFrom = [];

      const shipToGeos = this.shipToGeos.filter(
        (g) =>
          g.key === isoCode ||
          g.countryKey === isoCode ||
          g.parentKey === isoCode,
      );

      if (shipToGeos.length > 1) {
        shipTo = shipToGeos.filter((g) => g.key !== isoCode).map((g) => g.key);
      } else {
        shipTo = shipToGeos.map((g) => g.key);
      }

      const shipFromGeos = this.shipFromGeos.filter(
        (g) =>
          g.key === isoCode ||
          g.countryKey === isoCode ||
          g.parentKey === isoCode,
      );

      if (shipFromGeos.length > 1) {
        shipFrom = shipFromGeos
          .filter((g) => g.key !== isoCode)
          .map((g) => g.key);
      } else {
        shipFrom = shipFromGeos.map((g) => g.key);
      }

      this.filters.shipTo = shipTo;
      this.filters.shipFrom = shipFrom;
      this.geoLogic = 'OR';

      this.fetchTestDeck(this.testDeckId, {
        ...this.queryArgs,
        geoLogic: 'OR',
        shipTo,
        shipFrom,
      });
    },
    generateClickablePaths() {
      const self = this;
      this.$nextTick(() => {
        const svgMap = document.getElementById('test-deck-map');
        const countryPaths = svgMap.getElementsByClassName('svgMap-country');

        for (let country of countryPaths) {
          const isoCode = country.getAttribute('data-id');
          if (self.mapCountries[isoCode]) {
            country.addEventListener('click', () => {
              self.handleCountryClick(isoCode);
            });
            country.classList.add('clickable');
          }
        }
      });
    },
    doShowInvoiceDetails(item) {
      this.selectedInvoice = item;
      this.showInvoiceDetails = true;
    },

    formatMessage(key, value) {
      if (value) {
        return this.$t(`${key}.${value}`);
      }
      return this.$t(key);
    },
    fetchTestDeck(id, queryArgs) {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      const self = this;
      return new Promise((resolve, reject) => {
        api.tests.decks
          .getTestDeckDetails(id, queryArgs)
          .then((res) => {
            this.testDeck = {
              name: res.testDeck.name,
              filename: res.testDeck.filename,
              itemCount: res.testDeck.itemCount,
              description: res.testDeck.description?.trim(),
              createdAt: format(
                parseISO(`${res.testDeck.createdAt}Z`),
                'MMM dd, yyyy HH:mm',
              ),
            };

            self.taxEngineType = res.testDeck.taxEngineType;

            const content = res.content.map((o) => {
              const trx = mapTransaction(o, this.formatMessage);
              Object.keys(SBX_FORMAT_FIELDS).forEach((key) => {
                if (trx[key]) {
                  trx[key] = this.formatMessage(
                    SBX_FORMAT_FIELDS[key],
                    trx[key],
                  );
                }
              });

              return Object.freeze(trx);
            });

            const shipToGeos = res.filterOptions?.shipTo || [];
            const shipFromGeos = res.filterOptions?.shipFrom || [];

            self.content = content;
            self.shipToGeos = shipToGeos;
            self.shipFromGeos = shipFromGeos;
            const addressFields = {
              SHIP_FROM: false,
              SHIP_TO: false,
              BILL_TO: false,
              BUYER_PRIMARY: false,
              SELLER_PRIMARY: false,
              ORDER_ORIGIN: false,
              ORDER_ACCEPTANCE: false,
              SUPPLY: false,
              MIDDLEMAN: false,
            };

            const addrKeys = [
              'SHIP_FROM',
              'SHIP_TO',
              'BILL_TO',
              'BUYER_PRIMARY',
              'SELLER_PRIMARY',
              'ORDER_ORIGIN',
              'ORDER_ACCEPTANCE',
              'SUPPLY',
              'MIDDLEMAN',
            ];

            self.transactionFields = (
              res.testDeck.transactionFields || []
            ).reduce((acc, key) => {
              const addressKey = addrKeys.find((k) => key.includes(k));
              if (addressKey) {
                if (addressFields[addressKey] === false) {
                  addressFields[addressKey] = true;
                  const groupKey = `INVOICE_${addressKey}`;
                  acc.push(SBX_REWRITE_FIELDS[groupKey] || groupKey);
                }
              } else {
                acc.push(SBX_REWRITE_FIELDS[key] || key);
              }
              return acc;
            }, []);

            self.totalPages = res.totalPages;
            self.totalCount = res.totalElements;
            self.page = (res.pageable?.pageNumber || 0) + 1;

            const metrics = res.testDeck?.metadata?.metrics;
            if (metrics.currencies) {
              self.deckCurrencies = metrics.currencies || [];
            }

            if (metrics.documentTypes) {
              self.deckTransactionTypes = metrics.documentTypes || [];
            }

            resolve({
              testDeck: res.testDeck,
              content,
            });
          })
          .catch((err) => {
            this.$log.error(err);
            reject(err);
          })
          .finally(() => {
            this.$nextTick(() => {
              this.isLoading = false;
            });
          });
      });
    },
  },
};
</script>
