import svgMap from 'svgmap';
import {
  getFieldForProperty,
  AVA_FORMAT_FIELDS,
  AVA_FORMAT_FIELDS_KEYS,
} from '@/utils/ava/avaFieldUtils';

export function createSvgMap(countries) {
  return new svgMap({
    targetElementID: 'test-deck-map',
    colorMax: '#0D7930',
    colorMin: '#D6FAE2',
    noDataText: 'No relevant test data',
    flagURL: '/img/countries/svg/{0}.svg',
    data: {
      applyData: 'shipTo',

      data: {
        shipTo: {
          name: 'Ship To',
        },
        shipFrom: {
          name: 'Ship From',
        },
        total: {
          name: 'Total',
        },
      },
      values: countries,
    },
  });
}

function formatExpectationValue(
  field,
  exp,
  formatMessage = (k, v) => `${k}.${v}`,
) {
  const expValue = exp?.properties?.expectation || exp?.expectedValue;
  if (expValue) {
    const fmt = AVA_FORMAT_FIELDS[field.name] || field.i18n;
    if (fmt) {
      return formatMessage(fmt, expValue);
    } else {
      return expValue;
    }
  } else {
    return null;
  }
}

export function mapDocument(
  doc,
  formatMessage = (i18nkey, value) => `${i18nkey}.${value}`,
) {
  const documentProperties = Object.keys(doc.documentProperties).reduce(
    (props, key) => {
      const field = getFieldForProperty(key);
      if (field && !field.isAddress) {
        const fmt = AVA_FORMAT_FIELDS[field.name];
        if (fmt || field.i18n) {
          props[field.escaped] = formatMessage(
            fmt || field.i18n,
            doc.documentProperties[key],
          );
        } else {
          props[field.escaped] = doc.documentProperties[key];
        }
      }

      return props;
    },
    {},
  );

  if (doc.documentExpectations) {
    Object.keys(doc.documentExpectations || {}).forEach((key) => {
      const exp = doc.documentExpectations[key];
      if (exp) {
        const field = getFieldForProperty(key);
        if (field) {
          const formatted = formatExpectationValue(field, exp, formatMessage);

          if (formatted) {
            documentProperties[field.escaped] = formatted;
            doc.documentExpectations[key].properties.expectation = formatted;
          } else {
            documentProperties[field.escaped] = exp.properties?.expectation;
          }
        }
      }
    });
  }

  Object.keys(doc.addresses).forEach((group) => {
    const field = getFieldForProperty(group);
    if (field) {
      documentProperties[field.escaped] = doc.addresses[group];
    }
  });

  const lines = doc.lines.map((line, idx) => {
    return mapLine(doc, idx, line, formatMessage);
  });

  documentProperties['DOCUMENT_CURRENCY_CODE'] =
    doc.currencyCode?.toUpperCase();
  documentProperties['DOCUMENT_TYPE'] = formatMessage(
    'avaDocType',
    doc.documentType?.toUpperCase(),
  );
  documentProperties['_lines'] = lines;
  documentProperties['_value'] = Object.freeze(doc);
  return documentProperties;
}

function mapLine(
  doc,
  idx,
  line,
  formatMessage = (i18nkey, value) => `${i18nkey}.${value}`,
) {
  const output = {
    ...line,
    currencyCode: doc.currencyCode,
    key: `${doc.id}_${idx}_${line.lineNumber}`,
  };

  AVA_FORMAT_FIELDS_KEYS.forEach((key) => {
    if (output[key]) {
      output[key] = formatMessage(AVA_FORMAT_FIELDS[key], output[key]);
    }
  });

  if (output.expectations) {
    Object.keys(output.expectations || {}).map((key) => {
      const fmt = AVA_FORMAT_FIELDS[key];
      if (fmt) {
        const exp = output.expectations[key];
        const expValue = exp.properties?.expectation;
        if (expValue) {
          const formatted = formatMessage(fmt, expValue);
          if (formatted) {
            exp.properties.expectation = formatted;
          }
        }
      }
    });
  }

  return output;
}
