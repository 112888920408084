var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"line-detail-row sbx-line-detail-row",attrs:{"id":_vm.lineKey}},[_c('CCol',{staticClass:"line-number-col",attrs:{"col":"1"}},[_c('div',{staticClass:"text-uppercase text-muted small"},[_vm._v("Line")]),_c('div',{staticClass:"text-value-lg"},[_vm._v(_vm._s(_vm.lineNumber))])]),_c('CCol',{staticClass:"expectations-col",class:{ 'empty-col': !_vm.hasExpectations },attrs:{"col":"3"}},[_c('div',{staticClass:"col-label c-callout",class:{
        'text-muted': !_vm.hasExpectations,
        'c-callout-primary': _vm.hasExpectations,
      }},[_vm._v(" Expectations ")]),(_vm.hasExpectations)?_c('dl',{staticClass:"row expectations-list"},[_vm._l((_vm.expectations),function(expectation){return [_c('dt',{key:("exp_" + _vm.lineKey + "_" + (expectation.name) + "_dt"),staticClass:"col-5"},[_vm._v(" "+_vm._s(expectation.label)+": ")]),_c('dd',{key:("exp_" + _vm.lineKey + "_" + (expectation.name) + "_dd"),staticClass:"col-7"},[_c('pre',[_vm._v(_vm._s(expectation.value))])])]})],2):_c('div',{staticClass:"empty-col-section text-muted"},[_vm._v("None")])]),_c('CCol',{staticClass:"product-col",attrs:{"col":"3"}},[_c('div',{staticClass:"col-label c-callout c-callout-primary"},[_vm._v("Product")]),_c('dl',{staticClass:"row product-list"},[_c('dt',{staticClass:"col-5"},[_vm._v("Item Code")]),_c('dd',{staticClass:"col-7"},[_c('pre',[_vm._v(_vm._s(_vm.itemCode || '--'))])]),_c('dt',{staticClass:"col-5"},[_vm._v("Quantity:")]),_c('dd',{staticClass:"col-7"},[_c('pre',[_vm._v(_vm._s(_vm.quantity || '--'))])]),_c('dt',{staticClass:"col-5"},[_vm._v("Amount:")]),_c('dd',{staticClass:"col-7"},[_c('pre',[_vm._v(_vm._s(_vm.grossAmount || '--'))])])])]),_c('CCol',{staticClass:"custom-attributes-col",class:{ 'empty-col': !_vm.hasUserDefinedFields },attrs:{"col":"3"}},[_c('div',{staticClass:"col-label c-callout",class:{
        'text-muted': !_vm.hasUserDefinedFields,
        'c-callout-primary': _vm.hasUserDefinedFields,
      }},[_vm._v(" User Defined Fields ")]),(_vm.hasUserDefinedFields)?_c('dl',{staticClass:"row custom-attributes-list"},[_vm._l((_vm.userDefinedFields),function(attribute){return [_c('dt',{key:("attr_" + _vm.lineKey + "_" + (attribute.name) + "_dt"),staticClass:"col-5"},[_vm._v(" "+_vm._s(attribute.label)+": ")]),_c('dd',{key:("attr_" + _vm.lineKey + "_" + (attribute.name) + "_dd"),staticClass:"col-7"},[_c('pre',[_vm._v(_vm._s(attribute.value))])])]})],2):_c('div',{staticClass:"empty-col-section text-muted"},[_vm._v("None")])]),_c('CCol',{staticClass:"address-col",class:{ 'empty-col': !_vm.hasAddresses, 'text-muted': !_vm.hasAddresses },attrs:{"col":"2"}},[_c('div',{staticClass:"col-label c-callout",class:{
        'text-muted': !_vm.hasAddresses,
        'c-callout-primary': _vm.hasAddresses,
      }},[_vm._v(" Addresses ")]),(_vm.hasAddresses)?_c('dl',{staticClass:"row zone-address-list"},[_vm._l((_vm.addresses),function(address){return [_c('dt',{key:("addr_" + _vm.lineKey + "_" + (address.name) + "_dt"),staticClass:"col-5"},[_vm._v(" "+_vm._s(address.label)+": ")]),_c('dd',{key:("addr_" + _vm.lineKey + "_" + (address.name) + "_dd"),staticClass:"col-7"},[_c('ZoneAddress',{attrs:{"value":address}})],1)]})],2):_c('div',{staticClass:"empty-col-section text-muted"},[_vm._v("None")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }