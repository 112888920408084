<template>
  <CModal
    class="vtx-invoice-details-modal modal-xxl"
    :show.sync="modalShow"
    size="xl"
    centered
    :close-on-backdrop="false"
  >
    <template #header>
      <h2>Invoice: {{ documentNumber }}</h2>
    </template>
    <CCard class="document-details-card vtx-document-details-card">
      <CCardBody>
        <CRow>
          <CCol>
            <CCallout color="primary">
              <small class="text-muted">Company</small><br />
              <strong class="h4">{{ company }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout :color="!!division ? 'primary' : 'secondary'">
              <small class="text-muted">Division</small><br />
              <strong class="h4">{{ division || '--' }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout :color="!!department ? 'primary' : 'secondary'">
              <small class="text-muted">Department</small><br />
              <strong class="h4">{{ department || '--' }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout color="info">
              <small class="text-muted">Taxpayer Role</small><br />
              <strong class="h4">{{ taxpayerRole }}</strong>
            </CCallout>
          </CCol>
        </CRow>
        <CRow class="pt-2">
          <CCol col="4">
            <fieldset class="d-inline-flex">
              <legend>General</legend>
              <LabeledValue label="Invoice Date">
                {{ documentDate }}
              </LabeledValue>
              <LabeledValue label="Currency Code">
                {{ currencyCode }}
              </LabeledValue>
              <LabeledValue label="Transaction Type">
                {{ transactionType }}
              </LabeledValue>
            </fieldset>
          </CCol>
          <CCol>
            <fieldset class="d-inline-flex">
              <legend>Taxpayer Registrations</legend>
              <template v-if="registrations.length > 0">
                <LabeledValue
                  v-for="(reg, idx) in registrations"
                  :key="`${idx}_${reg.type}`"
                  :label="reg.type"
                >
                  {{ registration }}
                </LabeledValue>
              </template>
              <div v-else class="h5 text-muted mt-2 ml-1">None</div>
            </fieldset>
          </CCol>
          <CCol>
            <VtxCounterPartyBlock v-bind="counterparty" />
          </CCol>
        </CRow>
        <CTabs :active-tab.sync="activeTab" class="pt-5">
          <CTab title="Invoice" class="invoice-details-tab">
            <CCard>
              <CCardBody>
                <CRow class="pb-4">
                  <CCol>
                    <fieldset class="d-inline-flex">
                      <legend>Invoice Level Test Expectations</legend>
                      <template v-if="hasInvoiceExpectations">
                        <LabeledValue
                          label="Taxability"
                          :empty="!expectedDocTaxability"
                        >
                          {{
                            !!expectedDocTaxability
                              ? $tc(
                                  `taxabilityType.${expectedDocTaxability.toUpperCase()}`,
                                )
                              : '--'
                          }}
                        </LabeledValue>
                      </template>
                      <div v-else class="text-muted h4 mt-2 ml-1">
                        None <small class="ml-1">(see lines)</small>
                      </div>
                    </fieldset>
                  </CCol>
                </CRow>
                <CRow v-if="hasDocumentAddresses" class="pb-4">
                  <CCol>
                    <fieldset>
                      <legend>
                        Addresses
                        <small class="text-muted">
                          ({{ documentAddresses.length }})
                        </small>
                      </legend>
                      <CRow>
                        <CCol
                          v-for="invAddress in documentAddresses"
                          :key="invAddress.key"
                          col="6"
                          md="4"
                          lg="3"
                        >
                          <DocumentAddress
                            :value="invAddress"
                            :label="$t(`addressType.${invAddress.fieldName}`)"
                            class="pt-2"
                          />
                        </CCol>
                      </CRow>
                    </fieldset>
                  </CCol>
                </CRow>
                <CRow v-if="documentAttributes.length > 0" class="pb-4">
                  <CCol>
                    <fieldset>
                      <legend>All Other Invoice Properties</legend>
                      <div
                        v-if="documentAttributes.length > 0"
                        class="user-element-container"
                      >
                        <LabeledValue
                          v-for="e in documentAttributes"
                          :key="e.key"
                          :label="e.label"
                        >
                          <MCheckIcon
                            v-if="e.fieldType === 'BOOLEAN'"
                            :value="e.value"
                            show-false
                          />
                          <span v-else>
                            {{ e.value }}
                          </span>
                        </LabeledValue>
                      </div>
                      <div v-else class="text-muted h4 mt-2 ml-1">None</div>
                    </fieldset>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CTab>
          <CTab :title="`Lines (${lineCount})`" class="line-details-tab">
            <CTabs
              :active-tab="activeLineTab"
              class="pt-4"
              variant="pills"
              :vertical="{
                navs: 'col-xs-2 col-md-1',
                content: 'col-xs-10 col-md-11',
              }"
            >
              <CTab
                v-for="(line, index) in documentLines"
                :key="`${index}_${line.lineNumber}_${line.key}`"
                :title="`Line ${line.lineNumber}`"
              >
                <VtxInvoiceLineTab :line="line" />
              </CTab>
            </CTabs>
          </CTab>
          <CTab title="XML" class="xml-tab">
            <CCard>
              <CCardBody>
                <CRow>
                  <CCol class="d-flex"> </CCol>
                </CRow>
                <CRow>
                  <CCol style="position: relative">
                    <MClipboardButton
                      id="btn-vtx-invoice-details-xml-copy"
                      style="
                        margin-right: 1rem;
                        position: absolute;
                        top: 5px;
                        right: 25px;
                      "
                      @click="copyTextToClipBoard"
                    />
                    <pre
                      :id="xmlDetailsId"
                      class="code hljs xml"
                      v-html="documentXML.highlight"
                    ></pre>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CTab>
        </CTabs>
      </CCardBody>
    </CCard>
    <template #footer>
      <CButton
        id="btn-vtx-invoice-details-close"
        color="primary"
        @click="modalShow = false"
        >Close</CButton
      >
    </template>
  </CModal>
</template>
<script>
import { VtxCounterPartyBlock } from '@/components/TestDeck';
import DocumentAddress from '@/components/DocumentAddress';
import VtxInvoiceLineTab from './VtxInvoiceLineTab';
import { MClipboardButton } from '@/components/Buttons';
import { parseValue } from './VtxInvoiceDetailsUtils';

export default {
  name: 'VtxInvoiceDetailsModal',
  components: {
    VtxCounterPartyBlock,
    DocumentAddress,
    MClipboardButton,
    VtxInvoiceLineTab,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      activeLineTab: 0,
      ...parseValue(this.value),
    };
  },
  computed: {
    xmlDetailsId() {
      return `vtx-xml-details_${this.value.id}`;
    },
    modalShow: {
      get() {
        return this.show === true;
      },
      set(v) {
        this.$emit('update:show', v);
      },
    },
  },
  methods: {
    copyTextToClipBoard() {
      const copyText = document.createElement('textarea');
      const parent = document.getElementById(this.xmlDetailsId);
      copyText.value = this.documentXML.plain;
      copyText.setAttribute('readonly', 'true');
      copyText.style = { visibility: 'hidden' };
      parent.appendChild(copyText);
      copyText.select();
      navigator.clipboard.writeText(copyText.value);
      parent.removeChild(copyText);
    },
  },
};
</script>
<!--
<style lang="scss">
.document-details-card {
  padding-bottom: 0;
  margin-bottom: 0;
}

.tab-pane {
  .card {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@media (max-height: 900px) {
  .code.hljs {
    width: 100%;
    height: 250px;
    overflow-y: auto;
  }

  .tab-pane {
    height: 400px;
    overflow-y: auto;

    .tab-pane {
      height: unset;
      overflow: unset;
    }
  }
}

@media (min-height: 901px) {
  .code.hljs {
    width: 100%;
    height: 350px;
    overflow-y: auto;
  }

  .tab-pane {
    height: 500px;
    overflow-y: auto;

    .tab-pane {
      height: unset;
    }
  }
}

.user-element-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
}

.document-details-card {
  .c-callout {
    margin-top: 0;
  }
  .nav-pills .nav-link {
    color: #39f;
  }
  .nav-pills .nav-link.active {
    color: #fff;
    background-color: #39f;
  }

  fieldset {
    legend {
      font-size: 1rem;
      font-weight: 600;
      padding: 0;
      margin: 0;
    }
    & > div {
      margin-left: -0.25rem;
    }

    & > div.row {
      margin-left: -15px;
    }

    & > div.row > div > .document-address-container {
      margin-left: 0;
    }
  }
  .m-table-wrapper {
    height: 200px !important;
  }
}
</style>
-->
