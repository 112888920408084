<template>
  <CRow :id="lineKey" class="line-detail-row sbx-line-detail-row">
    <CCol col="1" class="line-number-col">
      <div class="text-uppercase text-muted small">Line</div>
      <div class="text-value-lg">{{ lineNumber }}</div>
    </CCol>

    <CCol
      col="3"
      class="expectations-col"
      :class="{ 'empty-col': !hasExpectations }"
    >
      <div
        class="col-label c-callout"
        :class="{
          'text-muted': !hasExpectations,
          'c-callout-primary': hasExpectations,
        }"
      >
        Expectations
      </div>
      <dl v-if="hasExpectations" class="row expectations-list">
        <template v-for="(expectation, idx) in expectations">
          <dt
            :key="`exp_${lineKey}_${idx}_${expectation.name}_dt`"
            class="col-5"
          >
            {{ expectation.label }}:
          </dt>
          <dd
            :key="`exp_${lineKey}_${idx}_${expectation.name}_dd`"
            class="col-7"
          >
            <pre>{{ expectation.value }}</pre>
          </dd>
        </template>
      </dl>
      <div v-else class="empty-col-section text-muted">None</div>
    </CCol>
    <CCol col="3" class="product-col">
      <div class="col-label c-callout c-callout-primary">Product</div>
      <dl class="row product-list">
        <template v-if="hasProduct">
          <template v-for="(product, index) in products">
            <dt
              :key="`prd_${lineKey}_${product.type}_${index}_dt`"
              class="col-5"
            >
              {{ product.type }}:
            </dt>
            <dd
              :key="`prd_${lineKey}_${product.type}_${index}_dd`"
              class="col-7"
            >
              <pre>{{ product.code }}</pre>
            </dd>
          </template>
        </template>
        <dt class="col-5">Quantity:</dt>
        <dd class="col-7">
          <pre>{{ quantity || '--' }}</pre>
        </dd>
        <dt class="col-5">Gross:</dt>
        <dd class="col-7">
          <pre>{{ grossAmount || '--' }}</pre>
        </dd>
      </dl>
    </CCol>
    <CCol
      col="3"
      class="custom-attributes-col"
      :class="{ 'empty-col': !hasCustomAttributes }"
    >
      <div
        class="col-label c-callout"
        :class="{
          'text-muted': !hasCustomAttributes,
          'c-callout-primary': hasCustomAttributes,
        }"
      >
        Custom Attributes
      </div>
      <dl v-if="hasCustomAttributes" class="row custom-attributes-list">
        <template v-for="attribute in customAttributes">
          <dt :key="`attr_${lineKey}_${attribute.name}_dt`" class="col-5">
            {{ attribute.label }}:
          </dt>
          <dd :key="`attr_${lineKey}_${attribute.name}_dd`" class="col-7">
            <pre>{{ attribute.value }}</pre>
          </dd>
        </template>
      </dl>
      <div v-else class="empty-col-section text-muted">None</div>
    </CCol>
    <CCol
      col="2"
      class="address-col"
      :class="{ 'empty-col': !hasAddresses, 'text-muted': !hasAddresses }"
    >
      <div
        class="col-label c-callout"
        :class="{
          'text-muted': !hasAddresses,
          'c-callout-primary': hasAddresses,
        }"
      >
        Addresses
      </div>
      <dl v-if="hasAddresses" class="row zone-address-list">
        <template v-for="address in addresses">
          <dt :key="`addr_${address.addressKey}_dt`" class="col-5">
            {{ address.label }}:
          </dt>
          <dd :key="`addr_${address.addressKey}_dd`" class="col-7">
            <ZoneAddress :value="address" />
          </dd>
        </template>
      </dl>
      <div v-else class="empty-col-section text-muted">None</div>
    </CCol>
  </CRow>
</template>
<script>
import ZoneAddress from '@/views/testing/_components/ZoneAddress';
import {
  SBX_LINE_FIELDS_MAP,
  // labelAttribute
} from '@/utils/sbxFieldUtils';

export default {
  name: 'SbxInvoiceLine',
  components: { ZoneAddress },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return this.mapLine(this.value);
  },
  methods: {
    mapLine(line) {
      const data = {
        lineKey: line.key,
        lineNumber: line.lineNumber,
        quantity: this.$format.number(line.quantity),
        grossAmount: line.grossAmount,
        products: [],
        hasProduct: false,
        hasAddresses: false,
        hasExpectations: false,
        addresses: line.addresses || [],
        expectations: (line.expectations || []).map((e) => {
          e.label = SBX_LINE_FIELDS_MAP[e.name]?.label || e.name;
          return e;
        }),
        customAttributes: line.customAttributes || [],
      };

      data.products = (line.product || line.products || []).map((p) => {
        return {
          type: this.$t(`productType.${p.type}`),
          code: p.code,
        };
      });

      data.hasProduct = data.products.length > 0;
      data.hasAddresses = data.addresses.length > 0;
      data.hasCustomAttributes = data.customAttributes.length > 0;
      data.hasExpectations = data.expectations.length > 0;

      return data;
    },
  },
};
</script>
